import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { Loader2 } from "lucide-react";
export const AnimatedHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const {
    primaryWallet,
    handleLogOut,
    setShowAuthFlow,
    user
  } = useDynamicContext();
  const isAuthenticated = !!user;
  const isPending = false; // We'll handle loading state differently

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Add effect for automatic redirection
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);
  const handleLoginClick = () => {
    if (primaryWallet) {
      navigate('/dashboard');
    } else {
      setShowAuthFlow(true);
    }
  };
  const renderAuthButton = () => {
    if (isPending) {
      return <Button variant="default" size="sm" disabled className="w-full sm:w-auto rounded-[12px] transition-all duration-700">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Loading...
        </Button>;
    }
    if (!isAuthenticated) {
      return <Button variant="default" size="sm" onClick={handleLoginClick} className="w-full sm:w-auto rounded-[12px] transition-all duration-700">
          Open App
        </Button>;
    }
    return null; // Don't render anything if authenticated
  };
  return <div className="fixed top-0 left-0 right-0 w-full z-50 flex justify-center">
      <header className={`transition-all duration-700 ease-in-out transform flex items-center justify-between will-change-transform
          ${isScrolled ? 'mt-4 bg-white/90 backdrop-blur-sm shadow-lg rounded-[18px] max-w-2xl mx-auto translate-y-0' : 'px-6 py-4 w-full max-w-7xl mx-auto translate-y-0'}`}>
        {!isScrolled && <div className="w-full flex items-center justify-between transition-all duration-700 ease-in-out transform opacity-100 motion-safe:animate-fade-in">
            <img src="/DPPn_blck.png" alt="DPP.network Logo" className="w-12 h-12 rounded-[12px] transition-all duration-700" />
            <div className="flex items-center gap-3">
              <Button variant="link" size="sm" onClick={() => window.location.href = 'mailto:hello@spmk.io'} className="w-full sm:w-auto transition-all duration-700">Join our Beta v.1.0.4</Button>
              {renderAuthButton()}
            </div>
          </div>}
        
        {isScrolled && <div className="grid grid-cols-[auto_auto] gap-[6px] items-center w-full px-[6px] py-[6px] transition-opacity duration-700 ease-in-out transform opacity-100 motion-safe:animate-fade-in">
            <div className="flex justify-start">
              <img src="/DPPn_blck.png" alt="DPP.network Logo" className="w-8 h-8 rounded-[12px] transition-all duration-700" />
            </div>
            <div className="flex justify-end">
              {renderAuthButton()}
            </div>
          </div>}
      </header>
    </div>;
};