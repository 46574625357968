import { encryptForIPFS, decryptFromIPFS } from "./encryption";

// Pinata API endpoints from environment variables
const PINATA_API_URL = import.meta.env.VITE_PINATA_FILE_API_URL || "https://api.pinata.cloud/pinning/pinFileToIPFS";
const PINATA_GATEWAY = import.meta.env.VITE_PINATA_GATEWAY_URL || "https://gateway.pinata.cloud/ipfs/";

interface IPFSUploadResult {
  publicUri: string;
  privateUri: string;
  encryptionKey?: string;
}

interface DPPData {
  basicInfo?: {
    productName?: string;
    category?: string;
    description?: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

interface VerifiableCredential {
  "@context": string[];
  type: string[];
  id: string;
  issuer: {
    id: string;
    name: string;
    type: string[];
  };
  issuanceDate: string;
  expirationDate?: string;
  credentialSubject: Record<string, unknown>;
}

export const uploadToIPFS = async (
  data: DPPData,
  manufacturerDid: string,
  manufacturerName: string
): Promise<IPFSUploadResult> => {
  try {
    // Create W3C Verifiable Credential structure for public data
    const publicCredential: VerifiableCredential = {
      "@context": [
        import.meta.env.VITE_W3C_CREDENTIALS_CONTEXT || "https://www.w3.org/ns/credentials/v2",
        import.meta.env.VITE_UNCEFACT_DPP_CONTEXT || "https://test.uncefact.org/vocabulary/untp/dpp/0.5.0/",
      ],
      type: ["DigitalProductPassport", "VerifiableCredential"],
      id: `did:dpp:${Date.now()}`,
      issuer: {
        id: manufacturerDid,
        name: manufacturerName,
        type: ["CredentialIssuer"],
      },
      issuanceDate: new Date().toISOString(),
      credentialSubject: {
        product: {
          name: data.basicInfo?.productName,
          category: data.basicInfo?.category,
          description: data.basicInfo?.description,
        },
        sustainability: data.sustainability,
        certifications: data.certifications,
      },
    };

    // Prepare private data
    const privateData = {
      manufacturing: data.manufacturing,
      materials: data.materials,
      documentation: data.documentation,
      lifecycle: data.lifecycle,
    };

    // Encrypt private data
    const { encryptedData: encryptedPrivateData, encryptionKey } =
      await encryptForIPFS(privateData, manufacturerDid);

    // Create Blobs and Files
    const publicBlob = new Blob([JSON.stringify(publicCredential)], {
      type: "application/json",
    });
    const privateBlob = new Blob([encryptedPrivateData], {
      type: "application/json",
    });
    const publicFile = new File([publicBlob], "public-metadata.json");
    const privateFile = new File([privateBlob], "private-metadata.json");

    // Upload to Pinata IPFS
    const publicCid = await pinFileToPinata(publicFile);
    const privateCid = await pinFileToPinata(privateFile);

    return {
      publicUri: `ipfs://${publicCid}`,
      privateUri: `ipfs://${privateCid}`,
      encryptionKey,
    };
  } catch (error) {
    console.error("Error uploading to IPFS:", error);
    throw new Error("Failed to upload metadata to IPFS");
  }
};

// Helper function to upload a file to Pinata
async function pinFileToPinata(file: File): Promise<string> {
  // Create form data
  const formData = new FormData();
  formData.append("file", file);

  // Add metadata
  const metadata = JSON.stringify({
    name: file.name,
    keyvalues: {
      type: "dpp-metadata",
      timestamp: Date.now().toString(),
    },
  });
  formData.append("pinataMetadata", metadata);

  // Add options
  const options = JSON.stringify({
    cidVersion: 1,
  });
  formData.append("pinataOptions", options);

  try {
    // Upload to Pinata using API Key
    const response = await fetch(PINATA_API_URL, {
      method: "POST",
      headers: {
        pinata_api_key: import.meta.env.VITE_PINATA_API_KEY,
        pinata_secret_api_key: import.meta.env.VITE_PINATA_API_SECRET,
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Pinata upload failed: ${errorData.error || response.statusText}`
      );
    }

    // Get CID from response
    const resData = await response.json();
    return resData.IpfsHash;
  } catch (error) {
    console.error("Error uploading to Pinata:", error);
    throw new Error("Failed to upload to Pinata IPFS");
  }
}
