
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { DPPFormData } from "@/types/dpp-form";
import { useToast } from "@/hooks/use-toast";
import { Spinner } from "@/components/ui/spinner";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { Separator } from "@/components/ui/separator";
import { useDPPMinting } from "@/hooks/useDPPMinting"; 
import { AlertCircle } from "lucide-react";

interface ReviewFormProps {
  formData: DPPFormData;
  onDataChange: (section: keyof DPPFormData, data: any) => void;
}

export function ReviewForm({ formData, onDataChange }: ReviewFormProps) {
  const { toast } = useToast();
  const [isMinting, setIsMinting] = useState(false);
  const { primaryWallet, user } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  const { mintDPP, txState, isLoadingAddresses, contractAddresses } = useDPPMinting();

  const handleMint = async () => {
    try {
      if (!isLoggedIn || !primaryWallet || !user) {
        toast({
          variant: "destructive",
          title: "Authentication Required",
          description: "Please connect your wallet to mint a DPP",
        });
        return;
      }

      if (isLoadingAddresses || !contractAddresses.complianceAddress) {
        toast({
          variant: "destructive",
          title: "Loading Contract Addresses",
          description: "Please wait while we load the contract addresses",
        });
        return;
      }

      setIsMinting(true);

      // Prepare the form data for minting
      const dppMetadata = {
        basicInfo: {
          productId: formData.productBasics.productId,
          idScheme: formData.productBasics.idScheme.id,
          productName: formData.productBasics.productName,
          manufacturerName: formData.manufacturingDetails.manufacturerName || "Unknown Manufacturer",
          category: Array.isArray(formData.productBasics.productCategory) && formData.productBasics.productCategory.length > 0 
            ? formData.productBasics.productCategory[0].name 
            : "Uncategorized",
          releaseDate: formData.productBasics.releaseDate.toISOString(),
          modelNumber: formData.productBasics.globalModelNumber,
          granularityLevel: formData.publicMetadata.granularityLevel || "item"
        },
        manufacturing: {
          country: formData.manufacturingDetails.manufacturingCountry,
          productionDate: formData.manufacturingDetails.productionDate.toISOString(),
          facility: formData.manufacturingDetails.facilityName,
          process: ""
        },
        materials: {
          composition: formData.materials.map(material => ({
            name: material.materialName,
            percentage: material.massFraction,
            isHazardous: material.hazardous,
            recycledContent: material.recycledContent
          })),
          hasHazardousMaterials: formData.materials.some(m => m.hazardous)
        },
        sustainability: {
          carbonFootprint: formData.sustainability.carbonFootprint,
          waterFootprint: formData.sustainability.waterScarcityFootprint,
          repairabilityIndex: formData.sustainability.repairabilityIndex,
          circularityScore: formData.sustainability.circularityScorecard.materialCircularityIndicator
        },
        certifications: {
          list: formData.compliance.certifications || [],
          evidence: formData.compliance.conformityClaims?.map(claim => claim.evidence.linkURL) || []
        },
        compliance: {
          standards: [],
          declarations: []
        }
      };

      // Call the mintDPP function
      const result = await mintDPP(dppMetadata);

      toast({
        title: "Success",
        description: "Digital Product Passport has been minted successfully!",
      });

      console.log('Minting successful:', result);

    } catch (error: any) {
      console.error('Error minting DPP:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to mint DPP. Please try again.",
      });
    } finally {
      setIsMinting(false);
    }
  };

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Review Your Digital Product Passport</h3>
          {/* Contract Address Status */}
          {isLoadingAddresses ? (
            <div className="flex items-center gap-2 text-muted-foreground">
              <Spinner className="h-4 w-4" />
              <span>Loading contract addresses...</span>
            </div>
          ) : !contractAddresses.complianceAddress ? (
            <div className="flex items-center gap-2 text-destructive">
              <AlertCircle className="h-4 w-4" />
              <span>Contract addresses not available. Cannot mint DPP.</span>
            </div>
          ) : (
            <div className="flex items-center gap-2 text-green-600">
              <span className="text-xs">✓ Contract addresses loaded successfully</span>
            </div>
          )}

          {/* Product Identity */}
          <div className="space-y-4">
            <h3 className="font-semibold">Product Identity</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-muted-foreground">Product Name</p>
                <p className="font-medium">{formData.productBasics.productName || "Not specified"}</p>
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Product ID</p>
                <p className="font-medium">{formData.productBasics.productId || "Not specified"}</p>
              </div>
            </div>
          </div>

          <Separator />

          {/* Manufacturing Details */}
          <div className="space-y-4">
            <h3 className="font-semibold">Manufacturing Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-muted-foreground">Manufacturer</p>
                <p className="font-medium">{formData.manufacturingDetails.manufacturerName || "Not specified"}</p>
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Production Country</p>
                <p className="font-medium">{formData.manufacturingDetails.manufacturingCountry || "Not specified"}</p>
              </div>
            </div>
          </div>

          <Separator />

          {/* Materials Summary */}
          <div className="space-y-4">
            <h3 className="font-semibold">Materials Summary</h3>
            <div>
              <p className="text-sm text-muted-foreground">Number of Materials</p>
              <p className="font-medium">{formData.materials.length}</p>
            </div>
          </div>

          <Separator />

          {/* Sustainability Metrics */}
          <div className="space-y-4">
            <h3 className="font-semibold">Sustainability Metrics</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-muted-foreground">Carbon Footprint</p>
                <p className="font-medium">{formData.sustainability.carbonFootprint || "Not specified"}</p>
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Repairability Index</p>
                <p className="font-medium">{formData.sustainability.repairabilityIndex || "Not specified"}</p>
              </div>
            </div>
          </div>
      </div>

      <div className="flex justify-end mt-12 py-6">
        <Button 
          onClick={handleMint} 
          disabled={isMinting || !isLoggedIn || isLoadingAddresses || !contractAddresses.complianceAddress}
          className="w-full md:w-auto bg-black hover:bg-black/90 text-white px-8 py-6 text-base"
        >
          {isMinting ? (
            <>
              <Spinner className="mr-2" />
              Minting...
            </>
          ) : (
            'Mint Digital Product Passport'
          )}
        </Button>
      </div>
    </div>
  );
}

export default ReviewForm;
