import { useState, useEffect } from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { supabase } from "@/integrations/supabase/client";
import { DraftCard } from "@/components/directory/DraftCard";
import { MintedDPPCard } from "@/components/directory/MintedDPPCard";
import { Card, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Search, Loader2, Plus, FileText, BarChart3 } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

export const DirectoryTab = () => {
  const { primaryWallet, user } = useDynamicContext();
  const navigate = useNavigate();
  const [drafts, setDrafts] = useState<any[]>([]);
  const [mintedDPPs, setMintedDPPs] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("all");

  // Fetch drafts and minted DPPs
  useEffect(() => {
    const fetchData = async () => {
      // Get wallet address from either primaryWallet or user object
      const walletAddress = primaryWallet?.address || user?.walletPublicKey;
      
      if (!walletAddress) {
        console.log('No wallet address found');
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        // Fetch drafts - using status to exclude already minted drafts
        const { data: draftsData, error: draftsError } = await supabase
          .from("product_drafts")
          .select("*")
          .eq("profile_id", walletAddress)
          .is("status", null) // Only get drafts that haven't been minted
          .order("last_auto_save", { ascending: false });

        if (draftsError) throw draftsError;
        setDrafts(draftsData || []);

        // Fetch minted DPPs
        const { data: mintedData, error: mintedError } = await supabase
          .from("minted_products_reference")
          .select("*")
          .eq("profile_id", walletAddress)
          .order("minted_at", { ascending: false });

        if (mintedError) throw mintedError;
        setMintedDPPs(mintedData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load your DPPs. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?.address]);

  // Function to handle refresh manually
  const handleRefresh = async () => {
    // Get wallet address from either primaryWallet or user object
    const walletAddress = primaryWallet?.address || user?.walletPublicKey;
    
    if (!walletAddress) {
      toast.error("Please connect your wallet to view your DPPs");

      // Try to open the Dynamic wallet modal if available
      try {
        if (
          window.Dynamic &&
          typeof window.Dynamic.showAuthFlow === "function"
        ) {
          window.Dynamic.showAuthFlow();
        }
      } catch (err) {
        console.error("Could not open Dynamic wallet modal:", err);
      }
      return;
    }

    setIsLoading(true);
    try {
      // Fetch drafts
      const { data: draftsData, error: draftsError } = await supabase
        .from("product_drafts")
        .select("*")
        .eq("profile_id", walletAddress)
        .is("status", null)
        .order("last_auto_save", { ascending: false });

      if (draftsError) throw draftsError;
      setDrafts(draftsData || []);

      // Fetch minted DPPs
      const { data: mintedData, error: mintedError } = await supabase
        .from("minted_products_reference")
        .select("*")
        .eq("profile_id", walletAddress)
        .order("minted_at", { ascending: false });

      if (mintedError) throw mintedError;
      setMintedDPPs(mintedData || []);

      toast.success("DPP list refreshed successfully");
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh your DPPs. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Filter function for both drafts and minted DPPs
  const filterItems = (items: any[]) => {
    return items.filter((item) => {
      // Handle different property names that might exist in different table schemas
      const name = item.name || item.product_name || "Unnamed DPP";
      const description = item.description || (item.form_data && JSON.parse(item.form_data)?.description) || "";
      const category = item.product_category || item.category || "";
      
      const matchesSearch =
        searchQuery === "" ||
        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        description.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesCategory =
        categoryFilter === "all" || category === categoryFilter;

      return matchesSearch && matchesCategory;
    });
  };

  const filteredDrafts = filterItems(drafts);
  const filteredMinted = filterItems(mintedDPPs);

  // Get unique categories from both drafts and minted DPPs
  const categories = Array.from(
    new Set([
      ...drafts.map((d) => d.product_category || d.category || null),
      ...mintedDPPs.map((m) => m.product_category || m.category || null),
    ])
  ).filter(Boolean);

  // Function to create a new DPP draft
  const handleCreateNewDPP = () => {
    navigate("/dashboard/create");
  };

  // Function to go to statistics page
  const handleViewStatistics = () => {
    navigate("/dashboard/statistics");
  };

  return (
    <div className="container mx-auto py-6 space-y-6">
      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
        <h1 className="text-2xl font-bold tracking-tight">
          My Digital Product Passports
        </h1>
        <div className="flex gap-2">
          <Button variant="default" onClick={handleCreateNewDPP}>
            <Plus className="w-4 h-4 mr-2" />
            Create New
          </Button>
          <Button variant="outline" onClick={handleViewStatistics}>
            <BarChart3 className="w-4 h-4 mr-2" />
            Statistics
          </Button>
          <Button
            variant="ghost"
            onClick={handleRefresh}
            disabled={isLoading}
            size="icon"
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <svg
                className="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            )}
          </Button>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground w-4 h-4" />
          <Input
            placeholder="Search DPPs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-9"
            disabled={isLoading}
          />
        </div>
        <Select
          value={categoryFilter}
          onValueChange={setCategoryFilter}
          disabled={isLoading || categories.length === 0}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Category" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Categories</SelectItem>
            {categories.map((category) => (
              <SelectItem key={category} value={category}>
                {category}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <Tabs
        defaultValue="all"
        className="space-y-4"
        value={activeTab}
        onValueChange={setActiveTab}
      >
        <TabsList>
          <TabsTrigger value="all" disabled={isLoading}>
            All ({filteredMinted.length + filteredDrafts.length})
          </TabsTrigger>
          <TabsTrigger value="minted" disabled={isLoading}>
            Minted ({filteredMinted.length})
          </TabsTrigger>
          <TabsTrigger value="drafts" disabled={isLoading}>
            Drafts ({filteredDrafts.length})
          </TabsTrigger>
        </TabsList>

        {isLoading ? (
          <div className="min-h-[400px] flex items-center justify-center">
            <div className="flex flex-col items-center gap-2">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="text-sm text-muted-foreground">
                Loading your DPPs...
              </p>
            </div>
          </div>
        ) : (
          <>
            <TabsContent value="all">
              {filteredDrafts.length === 0 && filteredMinted.length === 0 ? (
                <EmptyState
                  title="No DPPs found"
                  description="You haven't created any Digital Product Passports yet. Start by creating your first DPP."
                  icon={
                    <FileText className="h-10 w-10 text-muted-foreground" />
                  }
                  actionLabel="Create Your First DPP"
                  onAction={handleCreateNewDPP}
                />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {/* Show combined drafts and minted DPPs */}
                  {[...filteredMinted, ...filteredDrafts].map((item) => {
                    // Check for properties that would indicate it's a minted DPP
                    const isMinted = "token_id" in item || item.is_minted === true;
                    
                    // Let's add some console debug info to help troubleshoot
                    console.log('Rendering item:', { id: item.id, isMinted, item });
                    
                    return isMinted ? (
                      <MintedDPPCard key={item.id} dpp={item} />
                    ) : (
                      <DraftCard key={item.id} draft={item} />
                    );
                  })}
                </div>
              )}
            </TabsContent>

            <TabsContent value="minted">
              {filteredMinted.length === 0 ? (
                <EmptyState
                  title="No minted DPPs"
                  description="You haven't minted any Digital Product Passports yet. Mint a DPP from your drafts."
                  icon={
                    <FileText className="h-10 w-10 text-muted-foreground" />
                  }
                  actionLabel={
                    filteredDrafts.length > 0
                      ? "View Drafts"
                      : "Create Your First DPP"
                  }
                  onAction={() =>
                    filteredDrafts.length > 0
                      ? setActiveTab("drafts")
                      : handleCreateNewDPP()
                  }
                />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {filteredMinted.map((dpp) => {
                    console.log('Rendering minted DPP:', dpp);
                    return <MintedDPPCard key={dpp.id} dpp={dpp} />;
                  })}
                </div>
              )}
            </TabsContent>

            <TabsContent value="drafts">
              {filteredDrafts.length === 0 ? (
                <EmptyState
                  title="No draft DPPs"
                  description="You don't have any draft Digital Product Passports. Create a new DPP to get started."
                  icon={
                    <FileText className="h-10 w-10 text-muted-foreground" />
                  }
                  actionLabel="Create New DPP"
                  onAction={handleCreateNewDPP}
                />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {filteredDrafts.map((draft) => {
                    console.log('Rendering draft:', draft);
                    return <DraftCard key={draft.id} draft={draft} />;
                  })}
                </div>
              )}
            </TabsContent>
          </>
        )}
      </Tabs>
    </div>
  );
}

// Empty state component for when no items are found
function EmptyState({
  title,
  description,
  icon,
  actionLabel,
  onAction,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
  actionLabel: string;
  onAction: () => void;
}) {
  return (
    <div className="min-h-[400px] flex items-center justify-center">
      <div className="flex flex-col items-center text-center max-w-md gap-4 p-6">
        <div className="rounded-full bg-muted p-6">{icon}</div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-sm text-muted-foreground">{description}</p>
        <Button onClick={onAction}>{actionLabel}</Button>
      </div>
    </div>
  );
}
