import { motion } from "framer-motion";
import { Package, Shield, BarChart2, FileCheck, ChevronRight, ChevronLeft, Wallet, BrickWall, Building, Search as SearchIcon, SlidersHorizontal, Download as DownloadIcon } from "lucide-react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { useState } from "react";
import React from 'react';
import { useToast } from "./ui/use-toast";
import { Card } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Badge } from "./ui/badge";

const constructionMaterials = [
  {
    name: "Eco-Concrete Mix",
    description: "Low-carbon concrete mixture with recycled aggregates",
    category: "Concrete",
    productId: "ECO-001",
    certifications: ["Green Building Certified", "Low Carbon"],
    recycledContent: 45,
    icon: BrickWall
  },
  {
    name: "Sustainable Steel Beams",
    description: "Recycled steel structural components",
    category: "Steel",
    productId: "STL-002",
    certifications: ["ISO 14001", "EPD Verified"],
    recycledContent: 90,
    icon: Building
  }
];

const steps = [
  {
    icon: BarChart2,
    title: "Monitor your products",
    description: "Track and manage your products and DPPs with real-time data",
  },
  {
    icon: Package,
    title: "Create Digital Product Passports",
    description: "Generate immutable NFT-based DPPs for your products, securely storing all the required data on SPMK chain",
  },
  {
    icon: FileCheck,
    title: "Verified NFTs & transactions",
    description: "Verify product credentials and NFT-contract addresses stored on-chain",
  },
  {
    icon: Shield,
    title: "Access DPP Directory",
    description: "View and manage your verified DPPs in a secure, transparent directory powered by SPMK Chain",
  },
];

export const HowItWorks = () => {
  const [activeTab, setActiveTab] = useState("dashboard");

  return (
    <section className="py-8 md:py-16 lg:py-24 bg-[#fcfcfc]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top row - Headlines */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-12 mb-8 md:mb-12">
          <div className="space-y-4 md:space-y-6">
            <Badge variant="outline" className="mb-4 md:mb-6">Features</Badge>
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="text-2xl md:text-4xl lg:text-[62px] font-semibold leading-tight text-gray-800"
            >
              How it works?
            </motion.h2>
            <p className="text-base md:text-lg lg:text-xl text-gray-600">
              Have quick look how you can transform your product lifecycle management with our SPMK.network.
            </p>
          </div>
        </div>

        {/* Middle row - Interactive boxes and Demo */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-12">
          {/* Left column - Interactive text boxes */}
          <div className="space-y-4 md:space-y-8">
            {steps.map((step, index) => (
              <motion.div
                key={step.title}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`p-3 md:p-4 transition-all duration-200 rounded-lg hover:bg-primary/5 cursor-pointer ${
                  activeTab === ["dashboard", "create", "verify", "directory"][index] ? "bg-primary/10" : ""
                }`}
                onClick={() => setActiveTab(["dashboard", "create", "verify", "directory"][index])}
                role="button"
                tabIndex={0}
              >
                <div className="flex items-start gap-3 md:gap-4">
                  <div className="p-2 bg-white rounded-lg shadow-sm">
                    <step.icon className="h-5 w-5 md:h-6 md:w-6" />
                  </div>
                  <div>
                    <h3 className="text-base md:text-lg font-semibold mb-1 md:mb-2">{step.title}</h3>
                    <p className="text-sm md:text-base text-gray-600">{step.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Right column - Demo interface */}
          <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-4 md:p-6">
            <Tabs value={activeTab} className="w-full h-full" onValueChange={setActiveTab}>
              <TabsList className="grid w-full grid-cols-4 mb-4 md:mb-6">
                <TabsTrigger value="dashboard" className="text-xs md:text-sm">Dashboard</TabsTrigger>
                <TabsTrigger value="create" className="text-xs md:text-sm">Create DPP</TabsTrigger>
                <TabsTrigger value="verify" className="text-xs md:text-sm">Verify</TabsTrigger>
                <TabsTrigger value="directory" className="text-xs md:text-sm">Directory</TabsTrigger>
              </TabsList>

              <div className="h-full">
                <TabsContent value="dashboard" className="mt-2 md:mt-4 h-full">
                  <div className="space-y-3 md:space-y-4 h-full flex flex-col">
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3">
                      {[
                        { label: "Total Products", value: "156", icon: Package },
                        { label: "Active DPPs", value: "89", icon: Shield },
                        { label: "CO₂ Saved", value: "45.2t", icon: BarChart2 }
                      ].map((metric, i) => (
                        <div key={i} className="bg-gray-50 p-2 md:p-4 rounded-lg">
                          <metric.icon className="h-4 w-4 md:h-5 md:w-5 text-primary mb-1 md:mb-2" />
                          <div className="text-xs md:text-sm text-gray-600">{metric.label}</div>
                          <div className="text-sm md:text-lg font-semibold">{metric.value}</div>
                        </div>
                      ))}
                    </div>

                    <div className="space-y-2">
                      <h4 className="text-xs md:text-sm font-semibold">Top Products</h4>
                      {constructionMaterials.map((material) => (
                        <Card key={material.productId} className="p-2 md:p-3">
                          <div className="flex items-start space-x-2 md:space-x-3">
                            <div className="p-1.5 md:p-2 bg-gray-50 rounded">
                              <material.icon className="h-4 w-4 md:h-5 md:w-5 text-primary" />
                            </div>
                            <div className="flex-1 min-w-0">
                              <p className="text-sm md:text-base font-medium text-gray-900 truncate">
                                {material.name}
                              </p>
                              <p className="text-xs md:text-sm text-gray-500 truncate">
                                {material.description}
                              </p>
                              <div className="mt-1 flex flex-wrap gap-1">
                                {material.certifications.map((cert) => (
                                  <Badge key={cert} variant="secondary" className="text-[10px] md:text-xs">
                                    {cert}
                                  </Badge>
                                ))}
                              </div>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="create" className="mt-6 h-[400px]">
                  <Card className="p-6 h-full">
                    <div className="h-full flex flex-col space-y-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="text-lg font-semibold">Generate Digital Product Passport</h3>
                          <p className="text-sm text-muted-foreground">Create an immutable NFT-based DPP</p>
                        </div>
                        <Button variant="outline" className="gap-2">
                          <Wallet className="h-4 w-4" />
                          0x71...8ab3
                        </Button>
                      </div>

                      <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6">
                        <Card className="p-4 border-dashed border-2 flex items-center justify-center">
                          <div className="text-center">
                            <Package className="h-8 w-8 text-muted-foreground mx-auto mb-2" />
                            <p className="text-sm font-medium">Upload Product Image</p>
                            <p className="text-xs text-muted-foreground">Drag & drop or click</p>
                          </div>
                        </Card>
                        
                        <div className="space-y-6">
                          <div>
                            <label className="text-sm font-medium">Product Identity</label>
                            <div className="mt-2 flex gap-2">
                              <Badge variant="outline">Product ID</Badge>
                              <Badge variant="outline">Name</Badge>
                            </div>
                          </div>
                          
                          <div>
                            <label className="text-sm font-medium">Regulatory</label>
                            <div className="mt-2 flex gap-2">
                              <Badge variant="outline">Standards</Badge>
                            </div>
                          </div>
                          
                          <div>
                            <label className="text-sm font-medium">Sustainability</label>
                            <div className="mt-2 flex gap-2">
                              <Badge variant="outline">CO₂ Emissions</Badge>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2 text-sm">
                          <Shield className="h-4 w-4 text-green-500" />
                          <span className="text-green-600">Ready to generate</span>
                        </div>
                        <Button>Generate DPP</Button>
                      </div>
                    </div>
                  </Card>
                </TabsContent>

                <TabsContent value="verify" className="mt-6 h-[400px]">
                  <Card className="p-6 h-full">
                    <div className="space-y-6 h-full flex flex-col">
                      <div className="flex-1 flex items-center justify-center">
                        <div className="text-center">
                          <Shield className="h-12 w-12 text-green-500 mx-auto mb-4" />
                          <h3 className="text-lg font-semibold mb-2">NFT Successfully Deployed</h3>
                          <p className="text-sm text-muted-foreground mb-4">DPP verified on SPMK Chain</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4 text-sm">
                        <div>
                          <p className="text-muted-foreground">NFT Contract Address</p>
                          <p className="font-mono">0x8f...3e2d</p>
                        </div>
                        <div>
                          <p className="text-muted-foreground">Token ID</p>
                          <p className="font-mono">#1234</p>
                        </div>
                        <div>
                          <p className="text-muted-foreground">Blockchain Hash</p>
                          <p className="font-mono">0xab...7f9c</p>
                        </div>
                        <div>
                          <p className="text-muted-foreground">Deployment Date</p>
                          <p>{new Date().toLocaleDateString()}</p>
                        </div>
                      </div>
                      <div className="pt-4 flex gap-2">
                        <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
                          NFT Minted
                        </Badge>
                        <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
                          Verified on SPMK Chain
                        </Badge>
                      </div>
                    </div>
                  </Card>
                </TabsContent>

                <TabsContent value="directory" className="mt-6 h-[400px]">
                  <Card className="p-6 h-full">
                    <div className="space-y-6 h-full flex flex-col">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="text-lg font-semibold">Digital Product Passports</h3>
                          <p className="text-sm text-muted-foreground">89 DPPs found</p>
                        </div>
                        <div className="flex gap-2">
                          <Button variant="outline" size="icon">
                            <SlidersHorizontal className="h-4 w-4" />
                          </Button>
                          <Button variant="outline" size="icon">
                            <DownloadIcon className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>

                      <div className="relative">
                        <SearchIcon className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Input
                          placeholder="Search DPPs..."
                          className="pl-9"
                        />
                      </div>

                      <div className="flex gap-2">
                        <Badge variant="secondary" className="cursor-pointer">
                          All Categories
                        </Badge>
                        <Badge variant="outline" className="cursor-pointer">
                          Concrete
                        </Badge>
                        <Badge variant="outline" className="cursor-pointer">
                          Steel
                        </Badge>
                      </div>

                      <div className="flex-1 overflow-auto">
                        <Card className="p-4 hover:bg-accent/5 transition-colors cursor-pointer">
                          <div className="flex items-start space-x-4">
                            <div className="p-2 bg-primary/5 rounded-lg">
                              <Package className="h-6 w-6 text-primary" />
                            </div>
                            <div className="flex-1 min-w-0">
                              <div className="flex items-center justify-between">
                                <p className="text-sm font-medium text-foreground">
                                  Eco-Concrete Mix
                                </p>
                                <Badge variant="outline" className="text-xs">
                                  ECO-001
                                </Badge>
                              </div>
                              <p className="text-xs text-muted-foreground mt-1">
                                Low-carbon concrete mixture with recycled aggregates
                              </p>
                              <div className="mt-2 flex flex-wrap gap-1">
                                <Badge variant="secondary" className="text-xs">
                                  Green Building Certified
                                </Badge>
                                <Badge variant="secondary" className="text-xs">
                                  Low Carbon
                                </Badge>
                              </div>
                              <div className="mt-2 text-xs text-muted-foreground">
                                Recycled Content: 45%
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Card>
                </TabsContent>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};
