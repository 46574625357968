import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { HowItWorks } from "@/components/HowItWorks";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useState, useEffect } from "react";
import { LoginForm } from "@/components/auth/LoginForm";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { AnimatedHeader } from "@/components/AnimatedHeader";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import TerminalDemo from "@/components/TerminalDemo";
const Landing = () => {
  const navigate = useNavigate();
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const { toast } = useToast();
  const { setShowAuthFlow, user, primaryWallet } = useDynamicContext();

  // Redirect authenticated users to dashboard
  useEffect(() => {
    if (user && primaryWallet) {
      navigate("/dashboard");
    }
  }, [user, primaryWallet, navigate]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("success")) {
      setShowLoginDialog(true);
      toast({
        title: "Account created successfully!",
        description: "You can now log in to your account.",
      });
      window.history.replaceState({}, "", "/");
    } else if (params.get("canceled")) {
      toast({
        variant: "destructive",
        title: "Payment canceled",
        description: "Your subscription was not completed.",
      });
      window.history.replaceState({}, "", "/");
    }
  }, [toast]);

  // Listen for auth state changes
  supabase.auth.onAuthStateChange((event, session) => {
    if (event === "SIGNED_IN" && session) {
      setShowLoginDialog(false);
      navigate("/dashboard");
    }
    if (event === "USER_UPDATED" && !session) {
      toast({
        variant: "destructive",
        title: "Authentication Error",
        description:
          "Please try again or contact support if the problem persists.",
      });
    }
  });
  const handleCreateDPPClick = () => {
    if (!user) {
      setShowAuthFlow(true);
    } else {
      navigate("/dashboard");
    }
  };
  return (
    <div className="relative min-h-screen w-full overflow-x-hidden">
      <AnimatedHeader />

      {/* Scrollable Content */}
      <div className="relative w-full">
        {/* Hero Section */}
        <section className="relative min-h-screen flex items-end pt-24">
          {/* Background Video */}
          <video
            className="absolute top-0 left-0 w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src="/background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {/* Gradient Overlay */}
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent via-[#fcfcfc]/80 to-[#fcfcfc] z-10"></div>

          {/* Content Overlay */}
          <div className="relative z-20 max-w-7xl mx-auto w-full px-6 pb-8 md:pb-[5%]">
            <h1 className="w-full font-nudista font-semibold text-3xl md:text-[62px] leading-tight text-gray-800 mb-2">
              A decentralized ledger for verified
              <br className="hidden md:block" /> supply chains and transparent
              lifecycles.
            </h1>
            <p className="w-full font-bestchoice text-base text-gray-600 animate-fade-in mb-8 px-0 font-medium md:text-xl">
              Create Digital Product Passports that trace every material, verify
              every claim, and automate compliance.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Button
                onClick={handleCreateDPPClick}
                className="bg-black hover:bg-black/90 text-white px-6 w-full sm:w-auto"
              >
                Create your first DPP
              </Button>
              <Button
                variant="secondary"
                className="w-full sm:w-auto"
                onClick={() => (window.location.href = "mailto:hello@spmk.io")}
              >
                Request Beta Access
              </Button>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <HowItWorks />

        {/* Terminal Demo Section */}

        {/* ASCII Art Section */}
        <section className="py-24 bg-[#fcfcfc]">
          <div className="w-[90%] mx-auto">
            <pre className="font-mono text-gray-800 whitespace-pre w-full text-[1.2vw] leading-[1.2] text-center">
              {`      :::::::::  :::::::::  :::::::::     ::::    ::: :::::::::: ::::::::::: :::       :::  ::::::::  :::::::::  :::    ::: 
     :+:    :+: :+:    :+: :+:    :+:    :+:+:   :+: :+:            :+:     :+:       :+: :+:    :+: :+:    :+: :+:   :+:   
    +:+    +:+ +:+    +:+ +:+    +:+    :+:+:+  +:+ +:+            +:+     +:+       +:+ +:+    +:+ +:+    +:+ +:+  +:+     
   +#+    +:+ +#++:++#+  +#++:++#+     +#+ +:+ +#+ +#++:++#       +#+     +#+  +:+  +#+ +#+    +:+ +#++:++#:  +#++:++       
  +#+    +#+ +#+        +#+           +#+  +#+#+# +#+            +#+     +#+ +#+#+ +#+ +#+    +#+ +#+    +#+ +#+  +#+       
 #+#    #+# #+#        #+#       #+# #+#   #+#+# #+#            #+#      #+#+# #+#+#  #+#    #+# #+#    #+# #+#   #+#       
#########  ###        ###       ### ###    #### ##########     ###       ###   ###    ########  ###    ### ###    ###       `}
            </pre>
          </div>
        </section>

        {/* Footer */}
        <footer className="py-4 text-center bg-[#fcfcfc] relative z-50">
          <p className="text-xs text-gray-400">
            © 2025 Transparency Protocol Oy. All rights reserved.
          </p>
        </footer>
      </div>

      {/* Auth Dialog */}
      <Dialog open={showLoginDialog} onOpenChange={setShowLoginDialog}>
        <DialogContent className="sm:max-w-md">
          <LoginForm className="w-full" />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Landing;
