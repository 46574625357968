import React from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Eye, FileText, ArrowUpRight, Shield } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface MintedDPPCardProps {
  dpp: {
    id: string;
    name: string;
    description?: string;
    product_category: string;
    product_image?: string;
    token_id: string;
    transaction_hash: string;
    public_ipfs_uri: string;
    contract_address: string;
    minted_at: string;
    optional_public_metadata?: {
      manufacturerName?: string;
      materialCount?: number;
      sustainabilityScore?: number;
      complianceCertifications?: number;
    };
  };
}

export function MintedDPPCard({ dpp }: MintedDPPCardProps) {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/dashboard/view/${dpp.token_id}`);
  };

  const handleViewBlockExplorer = () => {
    // Determine which block explorer to use based on the contract address
    // For this example, we'll assume it's on Sepolia
    const blockExplorerUrl = `https://sepolia.etherscan.io/tx/${dpp.transaction_hash}`;
    window.open(blockExplorerUrl, "_blank", "noopener,noreferrer");
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Generate a circularity score for display (placeholder)
  const getCircularityScore = () => {
    if (dpp.optional_public_metadata?.sustainabilityScore) {
      return dpp.optional_public_metadata.sustainabilityScore;
    }
    // Random score between 70-95 if not available
    return Math.floor(Math.random() * 26) + 70;
  };

  const circularityScore = getCircularityScore();

  return (
    <Card className="relative overflow-hidden">
      {/* Minted Banner */}
      <div className="absolute top-0 right-0 left-0 bg-green-500/20 backdrop-blur-sm p-2 text-center">
        <Badge variant="secondary" className="bg-green-100 text-green-800 pointer-events-none">
          Minted
        </Badge>
      </div>

      <CardHeader className="pt-12">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-semibold">{dpp.name}</h3>
            <p className="text-sm text-muted-foreground">
              {dpp.product_category}
            </p>
          </div>
          {dpp.product_image ? (
            <img
              src={dpp.product_image}
              alt={dpp.name}
              className="w-16 h-16 object-cover rounded-md"
            />
          ) : (
            <div className="w-16 h-16 bg-muted rounded-md flex items-center justify-center">
              <FileText className="w-6 h-6 text-muted-foreground" />
            </div>
          )}
        </div>
      </CardHeader>

      <CardContent>
        <div className="space-y-4">
          {dpp.description && (
            <p className="text-sm text-muted-foreground line-clamp-2">
              {dpp.description}
            </p>
          )}

          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <span className="text-muted-foreground">Circularity:</span>{" "}
              <span className={`font-medium ${
                circularityScore >= 80 ? "text-green-600" : 
                circularityScore >= 60 ? "text-amber-600" : "text-red-600"
              }`}>
                {circularityScore}%
              </span>
            </div>
            <div>
              <span className="text-muted-foreground">Minted:</span>{" "}
              <span className="font-medium">
                {formatDate(dpp.minted_at)}
              </span>
            </div>
          </div>

          {/* Certifications/Verifications */}
          {dpp.optional_public_metadata?.complianceCertifications && (
            <div className="flex items-center gap-1 text-xs text-muted-foreground">
              <Shield className="w-3 h-3" />
              <span>
                {dpp.optional_public_metadata.complianceCertifications} certification{dpp.optional_public_metadata.complianceCertifications !== 1 ? "s" : ""}
              </span>
            </div>
          )}

          <div className="flex gap-2 mt-4">
            <Button
              variant="outline"
              className="flex-1"
              onClick={handleViewDetails}
            >
              <Eye className="w-4 h-4 mr-2" />
              View Details
            </Button>
            <Button 
              variant="default" 
              className="flex-1" 
              onClick={handleViewBlockExplorer}
            >
              <ArrowUpRight className="w-4 h-4 mr-2" />
              Verify on Chain
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
