import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { LifecycleFormData } from "@/types/dpp-form";

interface LifecycleComplianceFormProps {
  onDataChange: (data: Partial<LifecycleFormData>) => void;
  initialData?: Partial<LifecycleFormData>;
}

export function LifecycleComplianceForm({ onDataChange, initialData }: LifecycleComplianceFormProps) {
  const [formData, setFormData] = useState<Partial<LifecycleFormData>>(initialData || {
    conformityClaims: [],
    traceabilityEvents: [],
    tieredSupplierList: {
      suppliers: []
    },
    transportationLogs: { events: [] },
    customsDocumentation: { declarations: [] },
    serviceHistory: { services: [] },
    sparePartsCatalog: {
      parts: []
    },
    disassemblyInstructions: {
      instructions: {
        linkURL: '',
        linkName: '',
        linkType: 'document',
        hashDigest: '',
        hashMethod: 'SHA-256',
        encryptionMethod: 'none'
      },
      disassemblyTime: 0,
      disassemblyScore: 0,
      tools: [],
      difficulty: 'easy'
    },
    warrantyStatus: 'active',
    usagePatterns: {}
  });

  const handleChange = (field: keyof LifecycleFormData, value: any) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    onDataChange(updatedData);
  };

  const addTransportationEvent = () => {
    const events = formData.transportationLogs?.events || [];
    handleChange('transportationLogs', {
      events: [...events, {
        type: 'shipping',
        date: new Date().toISOString().split('T')[0],
        location: '',
        carrier: '',
        trackingNumber: ''
      }]
    });
  };

  const updateTransportationEvent = (index: number, field: string, value: any) => {
    const events = [...(formData.transportationLogs?.events || [])];
    events[index] = { ...events[index], [field]: value };
    handleChange('transportationLogs', { events });
  };

  const removeTransportationEvent = (index: number) => {
    const events = (formData.transportationLogs?.events || []).filter((_, i) => i !== index);
    handleChange('transportationLogs', { events });
  };

  const addCustomsDeclaration = () => {
    const declarations = formData.customsDocumentation?.declarations || [];
    handleChange('customsDocumentation', {
      declarations: [...declarations, {
        type: 'import',
        number: '',
        date: new Date().toISOString().split('T')[0],
        country: '',
        tariffCode: ''
      }]
    });
  };

  const updateCustomsDeclaration = (index: number, field: string, value: any) => {
    const declarations = [...(formData.customsDocumentation?.declarations || [])];
    declarations[index] = { ...declarations[index], [field]: value };
    handleChange('customsDocumentation', { declarations });
  };

  const removeCustomsDeclaration = (index: number) => {
    const declarations = (formData.customsDocumentation?.declarations || []).filter((_, i) => i !== index);
    handleChange('customsDocumentation', { declarations });
  };

  const addServiceRecord = () => {
    const services = formData.serviceHistory?.services || [];
    handleChange('serviceHistory', {
      services: [...services, {
        type: 'maintenance',
        date: new Date().toISOString().split('T')[0],
        technician: '',
        description: '',
        partsReplaced: []
      }]
    });
  };

  const updateServiceRecord = (index: number, field: string, value: any) => {
    const services = [...(formData.serviceHistory?.services || [])];
    services[index] = { ...services[index], [field]: value };
    handleChange('serviceHistory', { services });
  };

  const removeServiceRecord = (index: number) => {
    const services = (formData.serviceHistory?.services || []).filter((_, i) => i !== index);
    handleChange('serviceHistory', { services });
  };

  const addSparePart = () => {
    const newPart = {
      id: Date.now().toString(),
      name: '',
      availability: 'in-stock',
      price: 0
    };

    handleChange('sparePartsCatalog', {
      parts: [...(formData.sparePartsCatalog?.parts || []), newPart]
    });
  };

  const updateSparePart = (index: number, field: string, value: any) => {
    const updatedParts = [...(formData.sparePartsCatalog?.parts || [])];
    updatedParts[index] = { ...updatedParts[index], [field]: value };
    handleChange('sparePartsCatalog', { parts: updatedParts });
  };

  const removeSparePart = (index: number) => {
    const updatedParts = (formData.sparePartsCatalog?.parts || []).filter((_, i) => i !== index);
    handleChange('sparePartsCatalog', { parts: updatedParts });
  };

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Transportation & Customs</h3>
        <div className="space-y-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Label>Transportation Events Log</Label>
                <Button type="button" variant="outline" size="sm" onClick={addTransportationEvent}>
                  Add Event
                </Button>
              </div>
              <div className="space-y-4">
                {formData.transportationLogs?.events?.map((event, index) => (
                  <Card key={index} className="p-4">
                    <div className="space-y-4">
                      <div className="flex justify-between">
                        <div className="grid grid-cols-2 gap-4 flex-1 mr-4">
                          <Select
                            value={event.type}
                            onValueChange={(value) => updateTransportationEvent(index, 'type', value)}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select event type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="shipping">Shipping</SelectItem>
                              <SelectItem value="receiving">Receiving</SelectItem>
                              <SelectItem value="transfer">Transfer</SelectItem>
                            </SelectContent>
                          </Select>
                          <Input
                            type="date"
                            value={event.date}
                            onChange={(e) => updateTransportationEvent(index, 'date', e.target.value)}
                          />
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeTransportationEvent(index)}
                        >
                          Remove
                        </Button>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <Input
                          placeholder="Location"
                          value={event.location}
                          onChange={(e) => updateTransportationEvent(index, 'location', e.target.value)}
                        />
                        <Input
                          placeholder="Carrier"
                          value={event.carrier}
                          onChange={(e) => updateTransportationEvent(index, 'carrier', e.target.value)}
                        />
                      </div>
                      <Input
                        placeholder="Tracking Number"
                        value={event.trackingNumber}
                        onChange={(e) => updateTransportationEvent(index, 'trackingNumber', e.target.value)}
                      />
                    </div>
                  </Card>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Label>Customs Documentation</Label>
                <Button type="button" variant="outline" size="sm" onClick={addCustomsDeclaration}>
                  Add Declaration
                </Button>
              </div>
              <div className="space-y-4">
                {formData.customsDocumentation?.declarations?.map((declaration, index) => (
                  <Card key={index} className="p-4">
                    <div className="space-y-4">
                      <div className="flex justify-between">
                        <div className="grid grid-cols-2 gap-4 flex-1 mr-4">
                          <Select
                            value={declaration.type}
                            onValueChange={(value) => updateCustomsDeclaration(index, 'type', value)}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select declaration type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="import">Import</SelectItem>
                              <SelectItem value="export">Export</SelectItem>
                            </SelectContent>
                          </Select>
                          <Input
                            type="date"
                            value={declaration.date}
                            onChange={(e) => updateCustomsDeclaration(index, 'date', e.target.value)}
                          />
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeCustomsDeclaration(index)}
                        >
                          Remove
                        </Button>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <Input
                          placeholder="Declaration Number"
                          value={declaration.number}
                          onChange={(e) => updateCustomsDeclaration(index, 'number', e.target.value)}
                        />
                        <Input
                          placeholder="Country"
                          value={declaration.country}
                          onChange={(e) => updateCustomsDeclaration(index, 'country', e.target.value)}
                        />
                      </div>
                      <Input
                        placeholder="Tariff Code"
                        value={declaration.tariffCode}
                        onChange={(e) => updateCustomsDeclaration(index, 'tariffCode', e.target.value)}
                      />
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Maintenance & Repairs</h3>
        <div className="space-y-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Label>Service History Log</Label>
                <Button type="button" variant="outline" size="sm" onClick={addServiceRecord}>
                  Add Service Record
                </Button>
              </div>
              <div className="space-y-4">
                {formData.serviceHistory?.services?.map((service, index) => (
                  <Card key={index} className="p-4">
                    <div className="space-y-4">
                      <div className="flex justify-between">
                        <div className="grid grid-cols-2 gap-4 flex-1 mr-4">
                          <Select
                            value={service.type}
                            onValueChange={(value) => updateServiceRecord(index, 'type', value)}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select service type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="maintenance">Maintenance</SelectItem>
                              <SelectItem value="repair">Repair</SelectItem>
                              <SelectItem value="inspection">Inspection</SelectItem>
                            </SelectContent>
                          </Select>
                          <Input
                            type="date"
                            value={service.date}
                            onChange={(e) => updateServiceRecord(index, 'date', e.target.value)}
                          />
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeServiceRecord(index)}
                        >
                          Remove
                        </Button>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <Input
                          placeholder="Technician"
                          value={service.technician}
                          onChange={(e) => updateServiceRecord(index, 'technician', e.target.value)}
                        />
                        <Input
                          placeholder="Parts Replaced (comma-separated)"
                          value={service.partsReplaced.join(', ')}
                          onChange={(e) => updateServiceRecord(index, 'partsReplaced', e.target.value.split(',').map(p => p.trim()))}
                        />
                      </div>
                      <Input
                        placeholder="Description"
                        value={service.description}
                        onChange={(e) => updateServiceRecord(index, 'description', e.target.value)}
                      />
                    </div>
                  </Card>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Label>Spare Parts Catalog</Label>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={addSparePart}
                >
                  Add Part
                </Button>
              </div>
              <div className="space-y-4">
                {formData.sparePartsCatalog?.parts?.map((part, index) => (
                  <Card key={part.id} className="p-4">
                    <div className="space-y-4">
                      <div className="flex justify-between items-start">
                        <div className="grid grid-cols-2 gap-4 flex-1 mr-4">
                          <Input
                            placeholder="Part name"
                            value={part.name}
                            onChange={(e) => updateSparePart(index, 'name', e.target.value)}
                          />
                          <Input
                            type="number"
                            placeholder="Price"
                            value={part.price}
                            onChange={(e) => updateSparePart(index, 'price', parseFloat(e.target.value))}
                          />
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeSparePart(index)}
                        >
                          Remove
                        </Button>
                      </div>
                      <Select
                        value={part.availability}
                        onValueChange={(value) => updateSparePart(index, 'availability', value)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select availability" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="in-stock">In Stock</SelectItem>
                          <SelectItem value="out-of-stock">Out of Stock</SelectItem>
                          <SelectItem value="discontinued">Discontinued</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Product Lifecycle</h3>
        <div className="space-y-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Disassembly Instructions</Label>
              <div className="grid grid-cols-2 gap-4">
                <Input
                  placeholder="Instructions document name"
                  value={formData.disassemblyInstructions?.instructions?.linkName || ''}
                  onChange={(e) => handleChange('disassemblyInstructions', {
                    ...formData.disassemblyInstructions,
                    instructions: {
                      ...formData.disassemblyInstructions?.instructions,
                      linkName: e.target.value
                    }
                  })}
                />
                <Input
                  placeholder="Instructions URL"
                  value={formData.disassemblyInstructions?.instructions?.linkURL || ''}
                  onChange={(e) => handleChange('disassemblyInstructions', {
                    ...formData.disassemblyInstructions,
                    instructions: {
                      ...formData.disassemblyInstructions?.instructions,
                      linkURL: e.target.value
                    }
                  })}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <Label>Estimated Time (minutes)</Label>
                  <Input
                    type="number"
                    value={formData.disassemblyInstructions?.disassemblyTime || 0}
                    onChange={(e) => handleChange('disassemblyInstructions', {
                      ...formData.disassemblyInstructions,
                      disassemblyTime: parseInt(e.target.value)
                    })}
                  />
                </div>
                <div>
                  <Label>Difficulty Level</Label>
                  <Select
                    value={formData.disassemblyInstructions?.difficulty || 'easy'}
                    onValueChange={(value) => handleChange('disassemblyInstructions', {
                      ...formData.disassemblyInstructions,
                      difficulty: value as 'easy' | 'medium' | 'hard'
                    })}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select difficulty" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="easy">Easy</SelectItem>
                      <SelectItem value="medium">Medium</SelectItem>
                      <SelectItem value="hard">Hard</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <Label>Warranty Status</Label>
              <Input
                value={formData.warrantyStatus || ''}
                onChange={(e) => handleChange('warrantyStatus', e.target.value)}
                placeholder="Enter warranty status (e.g., '2 years remaining', 'Expired', 'Lifetime warranty')"
              />
            </div>

            <div className="space-y-2">
              <Label>Usage Patterns</Label>
              <div className="space-y-4">
                <p className="text-sm text-muted-foreground">Record usage data such as operating hours, performance metrics, and energy consumption.</p>
                <Textarea
                  value={typeof formData.usagePatterns === 'string' ? formData.usagePatterns : ''}
                  onChange={(e) => handleChange('usagePatterns', e.target.value)}
                  placeholder="Enter usage patterns and data..."
                  className="min-h-[150px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
