export const DPPContractABI = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "manufacturer",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "publicMetadataURI",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "dataSchemaVersion",
        "type": "string"
      }
    ],
    "name": "DPPMinted",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "manufacturer",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "publicMetadataURI",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "privateMetadataURI",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "productIdentifier",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "dataSchemaVersion",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "productName",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "manufacturerName",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "productCategory",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "productionDate",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "manufacturingCountry",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "carbonFootprint",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "repairabilityIndex",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "hasHazardousMaterials",
        "type": "bool"
      },
      {
        "internalType": "string[]",
        "name": "certifications",
        "type": "string[]"
      },
      {
        "internalType": "string[]",
        "name": "complianceStandards",
        "type": "string[]"
      }
    ],
    "name": "mintDPP",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
] as const;
