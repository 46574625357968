
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { SustainabilityFormData } from "@/types/dpp-form";

interface SustainabilityFormProps {
  onDataChange: (data: Partial<SustainabilityFormData>) => void;
  initialData?: Partial<SustainabilityFormData>;
}

export function SustainabilityForm({ onDataChange, initialData }: SustainabilityFormProps) {
  const [formData, setFormData] = useState<Partial<SustainabilityFormData>>({
    carbonFootprint: 0,
    declaredUnit: '',
    operationalScope: "CradleToGate",
    primarySourcedRatio: 0,
    circularityScorecard: {
      materialCircularityIndicator: 0,
      recycledContent: 0,
      recyclabilityFraction: 0,
      declaredUnit: '',
      utilityFactor: 0
    },
    emissionsScorecard: {
      carbonFootprint: 0,
      declaredUnit: '',
      operationalScope: 'CradleToGate',
      primarySourcedRatio: 0
    },
    energyEfficiencyClass: '',
    expectedLifespan: 0,
    repairabilityIndex: 0,
    waterScarcityFootprint: 0,
    biodiversityImpact: '',
    recyclingInstructions: '',
    takebackProgramDetails: '',
    recyclableContent: 0,
    reportingStandard: '',
    ...initialData
  });

  const handleChange = (field: keyof SustainabilityFormData, value: any) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    onDataChange(updatedData);
  };

  const handleEmissionsChange = (field: keyof typeof formData.emissionsScorecard, value: any) => {
    const updatedEmissions = {
      ...formData.emissionsScorecard,
      [field]: value
    };
    handleChange('emissionsScorecard', updatedEmissions);
  };

  const handleCircularityChange = (field: keyof typeof formData.circularityScorecard, value: any) => {
    const updatedCircularity = {
      ...formData.circularityScorecard,
      [field]: value
    };
    handleChange('circularityScorecard', updatedCircularity);
  };

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Emissions & Carbon Footprint</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Carbon Footprint (kg CO₂e)</Label>
              <Input
                type="number"
                min="0"
                step="0.01"
                placeholder="Enter carbon footprint"
                value={formData.emissionsScorecard?.carbonFootprint || ''}
                onChange={(e) => handleEmissionsChange('carbonFootprint', parseFloat(e.target.value))}
              />
            </div>
            
            <div className="space-y-2">
              <Label>Operational Scope</Label>
              <Select
                value={formData.emissionsScorecard?.operationalScope || 'CradleToGate'}
                onValueChange={(value) => handleEmissionsChange('operationalScope', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select operational scope" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="CradleToGate">Cradle to Gate</SelectItem>
                  <SelectItem value="CradleToCradle">Cradle to Cradle</SelectItem>
                  <SelectItem value="GateToCradle">Gate to Cradle</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>Declared Unit</Label>
              <Input
                placeholder="e.g., per kg, per unit"
                value={formData.emissionsScorecard?.declaredUnit || ''}
                onChange={(e) => handleEmissionsChange('declaredUnit', e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label>Primary Sourced Ratio (%)</Label>
              <Input
                type="number"
                min="0"
                max="100"
                step="0.1"
                placeholder="Enter primary sourced ratio"
                value={formData.emissionsScorecard?.primarySourcedRatio || ''}
                onChange={(e) => handleEmissionsChange('primarySourcedRatio', parseFloat(e.target.value))}
              />
            </div>
          </div>
      </div>

      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Circularity & Resource Efficiency</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Material Circularity Indicator (%)</Label>
              <Input
                type="number"
                min="0"
                max="100"
                step="0.1"
                placeholder="Enter material circularity"
                value={formData.circularityScorecard?.materialCircularityIndicator || ''}
                onChange={(e) => handleCircularityChange('materialCircularityIndicator', parseFloat(e.target.value))}
              />
            </div>

            <div className="space-y-2">
              <Label>Recyclable Content (%)</Label>
              <Input
                type="number"
                min="0"
                max="100"
                step="0.1"
                placeholder="Enter recyclable content"
                value={formData.recyclableContent || ''}
                onChange={(e) => handleChange('recyclableContent', parseFloat(e.target.value))}
              />
            </div>

            <div className="space-y-2">
              <Label>Utility Factor</Label>
              <Input
                type="number"
                min="0"
                step="0.1"
                placeholder="Enter utility factor"
                value={formData.circularityScorecard?.utilityFactor || ''}
                onChange={(e) => handleCircularityChange('utilityFactor', parseFloat(e.target.value))}
              />
            </div>

            <div className="space-y-2">
              <Label>Water Scarcity Footprint</Label>
              <Input
                type="number"
                min="0"
                step="0.1"
                placeholder="Enter water scarcity footprint"
                value={formData.waterScarcityFootprint || ''}
                onChange={(e) => handleChange('waterScarcityFootprint', parseFloat(e.target.value))}
              />
            </div>
          </div>
      </div>

      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Product Durability</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Expected Lifespan (years)</Label>
              <Input
                type="number"
                min="0"
                step="0.5"
                placeholder="Enter expected lifespan"
                value={formData.expectedLifespan || ''}
                onChange={(e) => handleChange('expectedLifespan', parseFloat(e.target.value))}
              />
            </div>
            
            <div className="space-y-2">
              <Label>Repairability Index (0-10)</Label>
              <Input
                type="number"
                min="0"
                max="10"
                step="0.1"
                placeholder="Enter repairability index"
                value={formData.repairabilityIndex || ''}
                onChange={(e) => handleChange('repairabilityIndex', parseFloat(e.target.value))}
              />
            </div>

            <div className="space-y-2">
              <Label>Energy Efficiency Class</Label>
              <Select
                value={formData.energyEfficiencyClass}
                onValueChange={(value) => handleChange('energyEfficiencyClass', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select energy efficiency class" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="A">A</SelectItem>
                  <SelectItem value="B">B</SelectItem>
                  <SelectItem value="C">C</SelectItem>
                  <SelectItem value="D">D</SelectItem>
                  <SelectItem value="E">E</SelectItem>
                  <SelectItem value="F">F</SelectItem>
                  <SelectItem value="G">G</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
      </div>

      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Environmental Impact & Documentation</h3>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Biodiversity Impact Assessment</Label>
              <Textarea
                placeholder="Enter biodiversity impact details"
                value={formData.biodiversityImpact || ''}
                onChange={(e) => handleChange('biodiversityImpact', e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label>Reporting Standard</Label>
              <Select
                value={formData.reportingStandard}
                onValueChange={(value) => handleChange('reportingStandard', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select reporting standard" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ISO_14067">ISO 14067</SelectItem>
                  <SelectItem value="GHG_PROTOCOL">GHG Protocol</SelectItem>
                  <SelectItem value="PAS_2050">PAS 2050</SelectItem>
                  <SelectItem value="ISO_14064">ISO 14064</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>Recycling Instructions</Label>
              <Textarea
                placeholder="Enter recycling instructions"
                value={formData.recyclingInstructions || ''}
                onChange={(e) => handleChange('recyclingInstructions', e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label>Take-back Program Details</Label>
              <Textarea
                placeholder="Enter take-back program details"
                value={formData.takebackProgramDetails || ''}
                onChange={(e) => handleChange('takebackProgramDetails', e.target.value)}
              />
            </div>
          </div>
      </div>
    </div>
  );
}
