import { keccak256, toBytes } from 'viem';

export const generateDid = async (address: string): Promise<string> => {
  // Create a DID using the did:web method
  // In production, this should be replaced with proper DID resolution
  const timestamp = Date.now().toString();
  const uniqueIdentifier = keccak256(
    toBytes(address + timestamp)
  ).slice(2, 18); // Take first 8 bytes
  
  return `did:web:dpp.network:manufacturer:${uniqueIdentifier}`;
};
