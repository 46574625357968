/**
 * Utility to validate the presence of required environment variables
 * Run this early in the application lifecycle to catch configuration issues
 */

export const validateEnv = () => {
  const requiredVars = [
    // Authentication & Wallet
    'VITE_SUPABASE_URL',
    'VITE_SUPABASE_ANON_KEY',
    'VITE_DYNAMIC_ENVIRONMENT_ID',
    'VITE_ZERODEV_BUNDLER_RPC',
    'VITE_ZERODEV_PAYMASTER_RPC',
    'VITE_ZERODEV_PROJECT_ID',
    
    // Storage
    'VITE_PINATA_API_KEY',
    'VITE_PINATA_API_SECRET',
    
    // DPPxPublic Network
    'VITE_DPPX_PUBLIC_RPC_URL',
    'VITE_DPPX_PUBLIC_CHAIN_ID',
    'VITE_DPPX_PUBLIC_COMPLIANCE_ADDRESS',
    'VITE_DPPX_PUBLIC_LIFECYCLE_ADDRESS',
    
    // DPPxPrivate Network
    'VITE_DPPX_PRIVATE_RPC_URL',
    'VITE_DPPX_PRIVATE_CHAIN_ID',
    'VITE_DPPX_PRIVATE_COMPLIANCE_ADDRESS',
    'VITE_DPPX_PRIVATE_LIFECYCLE_ADDRESS'
  ];
  
  const missingVars = requiredVars.filter(varName => !import.meta.env[varName]);
  
  if (missingVars.length > 0) {
    console.error(`Missing required environment variables: ${missingVars.join(', ')}`);
    return {
      isValid: false,
      missingVars
    };
  }
  
  return {
    isValid: true,
    missingVars: []
  };
};

/**
 * Gets an environment variable with fallback
 * @param key The environment variable key
 * @param fallback Optional fallback value if the environment variable is not defined
 * @returns The environment variable value or the fallback
 */
export const getEnv = (key: string, fallback?: string): string => {
  const value = import.meta.env[key];
  if (value === undefined) {
    if (fallback !== undefined) {
      return fallback;
    }
    console.warn(`Environment variable ${key} is not defined and no fallback was provided`);
    return '';
  }
  return value;
};
