import React, { useEffect, useState } from "react";
import AuthStatusHandler from "@/components/auth/AuthStatusHandler";
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
// Local type definitions to replace @dynamic-labs/sdk-api
// These are based on Dynamic Labs API structure

// Create enum-like objects with string values instead of just types
const SdkViewSectionType = {
  All: "all",
  ConnectWallet: "connect-wallet",
  WalletList: "wallet-list",
  WalletGroupList: "wallet-group-list",
  Email: "email"
} as const;

type SdkViewSectionType = typeof SdkViewSectionType[keyof typeof SdkViewSectionType];

const SdkViewType = {
  ConnectWallet: "connect-wallet",
  WalletList: "wallet-list",
  WalletGroupList: "wallet-group-list",
  SignMessage: "sign-message",
  Login: "login"
} as const;

type SdkViewType = typeof SdkViewType[keyof typeof SdkViewType];
import {
  DynamicContextProvider,
  type EvmNetwork,
  DynamicWidget,
} from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { ZeroDevSmartWalletConnectorsWithConfig } from "@dynamic-labs/ethereum-aa";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Loader2, AlertCircle } from "lucide-react";
import { createConfig, WagmiProvider } from "wagmi";
import { http } from "viem";
import { mainnet } from "viem/chains";
import { getOrMapViemChain } from "@dynamic-labs/ethereum-core";
import { validateEnv, getEnv } from "@/utils/env-validation";

import Dashboard from "./pages/Dashboard";
import Landing from "./pages/Landing";
import Slime from "./pages/Slime";
import { ThemeProvider } from "next-themes";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";

// --- Define custom networks first ---
const customEvmNetworks: EvmNetwork[] = [
  {
    blockExplorerUrls: ["https://subnets-test.avax.network/c-chain"],
    chainId: 43113,
    chainName: "Avalanche Fuji C-Chain",
    iconUrls: [
      "https://bafkreif2oorfm5xxvq4ll5xltmulo7khdqdt34gmiwgdg3dwz4wbu6kdsq.ipfs.flk-ipfs.xyz",
    ],
    name: "Avalanche Fuji C-Chain",
    nativeCurrency: {
      decimals: 18,
      name: "AVAX",
      symbol: "AVAX",
      iconUrl:
        "https://bafkreif2oorfm5xxvq4ll5xltmulo7khdqdt34gmiwgdg3dwz4wbu6kdsq.ipfs.flk-ipfs.xyz",
    },
    networkId: 43113,
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    vanityName: "Fuji Testnet",
    key: "avalanche-fuji",
    isTestnet: true,
  },
  {
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    chainId: parseInt(import.meta.env.VITE_DPPX_PUBLIC_CHAIN_ID || "41616"),
    chainName: "DPPxPublic",
    iconUrls: [
      "https://bafkreif2oorfm5xxvq4ll5xltmulo7khdqdt34gmiwgdg3dwz4wbu6kdsq.ipfs.flk-ipfs.xyz",
    ],
    name: "DPPxPublic",
    nativeCurrency: {
      decimals: 18,
      name: "DPP Token",
      symbol: "DPP",
      iconUrl:
        "https://bafkreif2oorfm5xxvq4ll5xltmulo7khdqdt34gmiwgdg3dwz4wbu6kdsq.ipfs.flk-ipfs.xyz",
    },
    networkId: parseInt(import.meta.env.VITE_DPPX_PUBLIC_CHAIN_ID || "41616"),
    rpcUrls: [
      import.meta.env.VITE_DPPX_PUBLIC_RPC_URL || "http://127.0.0.1:49263/ext/bc/mJfQtVANxzynVAC9FT8asioBPAEoSRTprtgk2raPh1tjMcEp5/rpc",
    ],
    vanityName: "DPP Public",
    key: "dpp-public",
    isTestnet: true,
  },
  {
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    chainId: parseInt(import.meta.env.VITE_DPPX_PRIVATE_CHAIN_ID || "41617"),
    chainName: "DPPxPrivate",
    iconUrls: [
      "https://bafkreif2oorfm5xxvq4ll5xltmulo7khdqdt34gmiwgdg3dwz4wbu6kdsq.ipfs.flk-ipfs.xyz",
    ],
    name: "DPPxPrivate",
    nativeCurrency: {
      decimals: 18,
      name: "DPPT Token",
      symbol: "DPPT",
      iconUrl:
        "https://bafkreif2oorfm5xxvq4ll5xltmulo7khdqdt34gmiwgdg3dwz4wbu6kdsq.ipfs.flk-ipfs.xyz",
    },
    networkId: parseInt(import.meta.env.VITE_DPPX_PRIVATE_CHAIN_ID || "41617"),
    rpcUrls: [
      import.meta.env.VITE_DPPX_PRIVATE_RPC_URL || "http://127.0.0.1:52308/ext/bc/2DStEDyg8pHLX5yJYSBAHa2qo2G5xm4uJzgNQAAzPCYCEujbjT/rpc",
    ],
    vanityName: "DPP Private",
    key: "dpp-private",
    isTestnet: true,
  },
];

// --- Convert custom networks to Viem chains ---
const customViemChains = customEvmNetworks.map(getOrMapViemChain);

// --- Create wagmi config ---
const wagmiConfig = createConfig({
  chains: customViemChains.length > 0 ? [customViemChains[0], ...customViemChains.slice(1)] : [mainnet],
  multiInjectedProviderDiscovery: false,
  transports: Object.fromEntries(
    customViemChains.map((chain) => [
      chain.id,
      http(chain.rpcUrls.default.http[0]),
    ])
  ),
});

// --- Set up react-query client ---
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    },
  },
});

// --- Utility functions ---
const verifySupabaseSession = async () => {
  try {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    console.log("Current Supabase session:", session);
    if (error) console.error("Error getting Supabase session:", error);
    return session;
  } catch (error) {
    console.error("Error verifying Supabase session:", error);
    return null;
  }
};

const syncDynamicUser = async (user: { publicAddress?: string; email?: string; userId: string; username?: string }) => {
  try {
    const response = await fetch("/api/dynamic-auth", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user }),
    });
    if (!response.ok) {
      throw new Error("Failed to sync user with Dynamic");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in syncDynamicUser:", error);
    throw error;
  }
};

// --- Environment Validation Component ---
const EnvironmentValidator = ({ children }: { children: React.ReactNode }) => {
  const [envStatus, setEnvStatus] = useState<{
    isValid: boolean;
    missingVars: string[];
  } | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const result = validateEnv();
    setEnvStatus(result);

    if (!result.isValid) {
      console.error("Missing environment variables:", result.missingVars);
      toast({
        variant: "destructive",
        title: "Configuration Error",
        description: `Missing required environment variables: ${result.missingVars.join(
          ", "
        )}. Please check your .env file.`,
      });
    }
  }, [toast]);

  if (envStatus === null) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Validating environment...</span>
      </div>
    );
  }

  if (envStatus && !envStatus.isValid) {
    return (
      <div className="h-screen w-full flex flex-col items-center justify-center p-6">
        <div className="bg-destructive/10 p-6 rounded-lg max-w-2xl">
          <div className="flex items-center text-destructive mb-4">
            <AlertCircle className="h-6 w-6 mr-2" />
            <h2 className="text-xl font-bold">
              Environment Configuration Error
            </h2>
          </div>
          <p className="mb-4">
            The following required environment variables are missing:
          </p>
          <ul className="list-disc list-inside mb-4">
            {envStatus.missingVars.map((variable) => (
              <li key={variable} className="ml-4">
                {variable}
              </li>
            ))}
          </ul>
          <p>
            Please check your <code>.env</code> file and make sure all required
            variables are set properly.
          </p>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

// --- Layout Component ---
const AppLayout = ({ children }: { children: React.ReactNode }) => (
  <div className="min-h-screen flex flex-col w-full">
    <div className="flex flex-1">
      <main className="flex-1 flex flex-col">
        <div className="p-6 flex-1 bg-black/[0.13]">{children}</div>
      </main>
    </div>
  </div>
);

// --- Application Routes & UI ---
const AppContent = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/slime" element={<Slime />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <AppLayout>
                <Dashboard />
              </AppLayout>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Toaster />
      <Sonner />
    </>
  );
};

// --- Wrapper Component that provides theme, context, and authentication events ---
const AppWrapper = () => {
  const { toast } = useToast();
  const navigate = useNavigate();

  // Define proper view configuration for auth flow - support both wallet connection and email
  // By not setting strict view overrides, we allow the full auth flow to complete

  // Add a function to create Supabase session based on Dynamic authentication
  const createSupabaseSession = async (user: { publicAddress?: string; email?: string; userId: string; username?: string }) => {
    try {
      // Check if session already exists
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        console.log("Supabase session already exists");
        return session;
      }

      // Create a new session using email if available
      if (user.email) {
        // This approach requires your backend to create a matching account
        // or you can use Magic Links via Supabase
        const { data, error } = await supabase.auth.signInWithOtp({
          email: user.email,
          options: {
            // This creates a magic link but doesn't send an email
            // You'll need server-side code to actually link the accounts
            shouldCreateUser: false,
          },
        });

        if (error) throw error;
        return data.session;
      }

      return null;
    } catch (error) {
      console.error("Error creating Supabase session:", error);
      throw error;
    }
  };

  return (
    <ThemeProvider defaultTheme="light" attribute="class">
      <TooltipProvider>
        <DynamicContextProvider
          settings={{
            environmentId: import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID || getEnv(
              "VITE_DYNAMIC_ENVIRONMENT_ID",
              "8e9fe923-3367-47df-971f-066f1a293300"
            ),
            walletConnectors: [
              EthereumWalletConnectors,
              ZeroDevSmartWalletConnectorsWithConfig({
                bundlerRpc: import.meta.env.VITE_ZERODEV_BUNDLER_RPC,
                paymasterRpc: import.meta.env.VITE_ZERODEV_PAYMASTER_RPC,
                // projectId is set in the environment
              }),
            ],
            overrides: {
              evmNetworks: (dashboardNetworks: EvmNetwork[]) => [
                ...dashboardNetworks,
                ...customEvmNetworks,
              ],
              // Remove restrictive view overrides to allow the complete auth flow
            },
            debugError: true, // Enable debug error logging for development
            // Using environment-specific configuration options
            events: {
              onAuthSuccess: async (params) => {
                const args = { 
                  user: {
                    id: params.user.userId || '',
                    walletPublicKey: params.primaryWallet?.address,
                    verifiedCredentials: params.primaryWallet ? [{ address: params.primaryWallet.address }] : [],
                    email: params.user.email,
                    username: params.user.username || 'Anonymous',
                    alias: params.user.alias
                  }
                };
                console.log("Dynamic Auth Success:", args);
                if (!args?.user) {
                  console.error("No user data in auth success");
                  return;
                }
                
                // Store wallet connection status in localStorage for persistence
                const walletAddress = args.user.walletPublicKey || args.user.verifiedCredentials?.[0]?.address;
                if (walletAddress) {
                  localStorage.setItem('walletConnected', 'true');
                  localStorage.setItem('walletAddress', walletAddress);
                }
                try {
                  // Log user info to verify wallet connection
                  console.log("User authenticated:", { 
                    id: args.user.id,
                    walletAddress: args.user.walletPublicKey || args.user.verifiedCredentials?.[0]?.address,
                    email: args.user.email 
                  });
                  
                  // Verify the wallet is properly connected
                  const walletAddress = args.user.walletPublicKey || args.user.verifiedCredentials?.[0]?.address;
                  if (!walletAddress) {
                    console.warn("User authenticated but no wallet address found. Auth may be incomplete.");
                  }
                  
                  // Log Supabase URL to verify it's correct
                  console.log("Using Supabase URL:", import.meta.env.VITE_SUPABASE_URL || "fallback URL");
                  
                  try {
                    // Store user data in Supabase
                    const { data: userData, error: userError } = await supabase
                      .from("dynamic_user_info")
                      .upsert(
                        {
                          id: args.user.id,
                          dynamic_user_id: args.user.id,
                          primary_wallet_address:
                            args.user.walletPublicKey ||
                            args.user.verifiedCredentials?.[0]?.address,
                          dynamic_metadata: {
                            email: args.user.email,
                            username: args.user.username || args.user.alias || "Anonymous",
                            last_login: new Date().toISOString()
                          },
                          updated_at: new Date().toISOString(),
                        },
                        {
                          onConflict: "id",
                        }
                      );

                    if (userError) {
                      console.error("Error storing user data in Supabase:", userError);
                      // Continue with the flow even if Supabase storage fails
                    } else {
                      console.log("User data stored in Supabase:", userData);
                    }
                  } catch (tableError) {
                    console.error("Error accessing users table. It may not exist:", tableError);
                    // Continue with the flow even if the table doesn't exist
                  }

                  // REMOVED: Supabase email authentication to prevent unwanted emails
                  /*
                  try {
                    await createSupabaseSession(args.user);
                  } catch (sessionError) {
                    console.error("Error creating Supabase session, continuing anyway:", sessionError);
                  }
                  */

                  // Continue with existing logic
                  try {
                    await syncDynamicUser({
            userId: args.user.id,
            publicAddress: args.user.walletPublicKey || args.user.verifiedCredentials?.[0]?.address,
            email: args.user.email,
            username: args.user.username || args.user.alias || "Anonymous"
          });
                  } catch (syncError) {
                    console.error("Error syncing Dynamic user, continuing anyway:", syncError);
                  }
                  
                  toast({
                    title: "Success",
                    description: "Successfully connected wallet",
                  });
                  navigate("/dashboard");
                } catch (error) {
                  console.error("Error syncing Dynamic user:", error);
                  toast({
                    variant: "destructive",
                    title: "Authentication Failed",
                    description: "Failed to sync user data. Please try again.",
                  });
                }
              },
              onLogout: async () => {
                try {
                  await supabase.auth.signOut();
                  toast({
                    title: "Logged Out",
                    description: "Successfully logged out",
                  });
                  navigate("/");
                } catch (error) {
                  console.error("Error logging out:", error);
                  toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Failed to log out properly",
                  });
                }
              },
              onAuthFlowOpen: () => {
                console.log("Auth flow opened");
                verifySupabaseSession();
              },
              onAuthFlowClose: () => {
                console.log("Auth flow closed");
              },
              onAuthFlowCancel: () => {
                console.log("Auth flow cancelled");
                // Only show the cancellation toast if there's no connected wallet
                // This prevents the message from showing up after successful connection
                const dynamicContext = window.Dynamic?.ctx;
                const hasConnectedWallet = dynamicContext?.primaryWallet?.address || dynamicContext?.user?.verifiedCredentials?.[0]?.address;
                
                if (!hasConnectedWallet) {
                  toast({
                    title: "Authentication Cancelled",
                    description: "You've cancelled the wallet connection process.",
                  });
                }
              },
              onAuthFailure: (data, reason) => {
                const error = reason && typeof reason === 'object' && 'error' in reason ? reason.error : {};
                const errorMessage = error instanceof Error ? error.message : typeof error === 'string' ? error : 'Unknown error';
                console.error("Auth failed:", error);
                let displayMessage = "Failed to authenticate with wallet";
                if (errorMessage.includes("Proposal expired")) {
                  displayMessage =
                    "Wallet connection request expired. Please try again.";
                } else if (errorMessage.includes("User rejected")) {
                  displayMessage =
                    "You've rejected the connection request. Please try again.";
                }
                toast({
                  variant: "destructive",
                  title: "Authentication Failed",
                  description: displayMessage,
                });
              },
              onWalletAdded: async (data) => {
                const wallet = data.wallet;
                console.log("New wallet added:", wallet);
                const dynamicUser = await window.dynamic?.user.get();
                if (dynamicUser) {
                  await syncDynamicUser(dynamicUser);
                }
              },
              onWalletRemoved: () => {
                console.log("Wallet disconnected");
              },
            },
          }}
        >
          <DynamicWagmiConnector>
            {/* Add the AuthStatusHandler to properly manage auth state */}
            <AuthStatusHandler />
            <AppContent />
          </DynamicWagmiConnector>
        </DynamicContextProvider>
      </TooltipProvider>
    </ThemeProvider>
  );
};

// --- Top-level App Component ---
const App = () => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <EnvironmentValidator>
            <AppWrapper />
          </EnvironmentValidator>
        </BrowserRouter>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
