import { useNavigate } from "react-router-dom";
import { 
  useDynamicContext, 
  useIsLoggedIn
} from "@dynamic-labs/sdk-react-core";
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useRef } from "react";

/**
 * AuthStatusHandler - A component that doesn't render anything but manages
 * Dynamic authentication states and wallet connection status
 */
const AuthStatusHandler = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user, primaryWallet, authToken, auth, setShowAuthFlow } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  
  // Use refs to track previous state for comparison
  const prevWalletRef = useRef<string | null>(null);
  const prevAuthTokenRef = useRef<string | null>(null);
  const prevIsLoggedInRef = useRef<boolean | null>(null);

  // Handle successful login and wallet connection
  useEffect(() => {
    // Check if we just got logged in with a wallet
    const justLoggedIn = isLoggedIn && !prevIsLoggedInRef.current;
    const walletConnected = primaryWallet && primaryWallet.address;
    const walletChanged = walletConnected && prevWalletRef.current !== primaryWallet.address;
    const newAuthToken = authToken && prevAuthTokenRef.current !== authToken;
    
    if (isLoggedIn && walletConnected) {
      // Store wallet connection in localStorage for persistence
      localStorage.setItem('walletConnected', 'true');
      localStorage.setItem('walletAddress', primaryWallet.address);
      
      // If this is a new login or wallet connection
      if (justLoggedIn || walletChanged || newAuthToken) {
        console.log("New login/wallet detected:", primaryWallet.address);
        
        // Explicitly hide the auth flow as recommended by Dynamic support
        if (auth) {
          auth.hide();
        }
        
        // Show success toast
        toast({
          title: "Success",
          description: "Successfully connected wallet",
        });
        
        // Navigate to dashboard after successful connection
        navigate("/dashboard");
      }
    } else if (!isLoggedIn && localStorage.getItem('walletConnected') === 'true') {
      // User was connected but is no longer - clean up localStorage
      localStorage.removeItem('walletConnected');
      localStorage.removeItem('walletAddress');
    }
    
    // Update refs for next comparison
    prevIsLoggedInRef.current = isLoggedIn;
    prevWalletRef.current = primaryWallet?.address || null;
    prevAuthTokenRef.current = authToken || null;
  }, [isLoggedIn, primaryWallet, authToken, auth, toast, navigate]);

  // Handle user logout  
  useEffect(() => {
    if (prevIsLoggedInRef.current && !isLoggedIn) {
      console.log("User logged out");
      localStorage.removeItem('walletConnected');
      localStorage.removeItem('walletAddress');
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  // Cleanup on component unmount/page refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      // This won't actually run on page refresh/close due to how browsers work,
      // but it's good practice to clean up
      if (!isLoggedIn && localStorage.getItem('walletConnected') === 'true') {
        localStorage.removeItem('walletConnected');
        localStorage.removeItem('walletAddress');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isLoggedIn]);

  return null; // This component doesn't render anything, just handles state changes
};

export default AuthStatusHandler;
