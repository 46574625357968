
import React, { useState } from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { PenLine, Coins, Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { supabase } from "@/integrations/supabase/client";
import { uploadToIPFS } from "@/utils/ipfs";
import { getContractAddresses } from "@/utils/contractAddresses";
import { getContract, hashProductData } from "@/utils/web3Config";
import { loadDppDraft } from "@/utils/dpp-draft-utils";

interface DraftCardProps {
  draft: {
    id: string;
    name: string;
    description?: string;
    product_category: string;
    product_image?: string;
    last_step: number;
    last_auto_save: string;
    optional_public_metadata?: {
      manufacturerName?: string;
      materialCount?: number;
      sustainabilityScore?: number;
      complianceCertifications?: number;
    };
    profile_id: string;
  };
}

export function DraftCard({ draft }: DraftCardProps) {
  const navigate = useNavigate();
  const { primaryWallet: user } = useDynamicContext();
  const [isMinting, setIsMinting] = useState(false);

  const handleContinueEditing = () => {
    navigate(`/dashboard/create/${draft.id}`);
  };

  const handleMint = async () => {
    if (!user?.address) {
      toast.error("Please connect your wallet to mint a DPP");
      
      // Try to open the Dynamic wallet modal if available
      try {
        if (
          window.Dynamic &&
          typeof window.Dynamic.showAuthFlow === "function"
        ) {
          window.Dynamic.showAuthFlow();
        }
      } catch (err) {
        console.error("Could not open Dynamic wallet modal:", err);
      }
      return;
    }
    
    try {
      setIsMinting(true);
      toast.info("Starting the minting process...");
      
      // 1. Load the full draft data
      const draftResult = await loadDppDraft(draft.id, draft.profile_id);
      if (!draftResult.success || !draftResult.formData) {
        throw new Error("Failed to load draft data: " + draftResult.error);
      }
      
      const dppData = draftResult.formData;
      
      // 2. Upload metadata to IPFS
      const manufacturerDid = `did:ethr:${user.address}`;
      const manufacturerName = dppData.manufacturing?.manufacturerName || "Unknown Manufacturer";
      
      const ipfsResult = await uploadToIPFS(
        dppData,
        manufacturerDid,
        manufacturerName
      );
      
      toast.info("DPP data uploaded to IPFS");
      
      // 3. Get contract addresses
      const contractAddresses = await getContractAddresses();
      
      // 4. Hash the product data for on-chain verification
      const productDataHash = hashProductData(dppData);
      
      // 5. Mint the NFT using the contract
      const contract = await getContract();
      const mintTx = await contract.mint(
        user.address,
        ipfsResult.publicUri,
        productDataHash
      );
      
      toast.info("Transaction submitted, waiting for confirmation...");
      
      // 6. Wait for transaction confirmation
      const receipt = await mintTx.wait();
      
      // 7. Get the token ID from the event logs
      let tokenId;
      for (const event of receipt.logs) {
        // Parse the event to get the token ID
        try {
          const parsedLog = contract.interface.parseLog(event);
          if (parsedLog?.name === "Transfer") {
            tokenId = parsedLog.args[2]; // Typically the third argument is the tokenId in ERC721 Transfer events
            break;
          }
        } catch (e) {
          // Not all logs can be parsed as contract events
          continue;
        }
      }
      
      if (!tokenId) {
        throw new Error("Could not determine token ID from transaction");
      }
      
      // 8. Create a record in the minted_products_reference table
      const { error: mintedInsertError } = await supabase
        .from("minted_products_reference")
        .insert({
          profile_id: draft.profile_id,
          name: draft.name,
          description: draft.description,
          product_category: draft.product_category,
          product_image: draft.product_image,
          token_id: tokenId.toString(),
          transaction_hash: receipt.hash,
          public_ipfs_uri: ipfsResult.publicUri,
          private_ipfs_uri: ipfsResult.privateUri,
          contract_address: contractAddresses.public.lifecycle,
          minted_at: new Date().toISOString(),
          optional_public_metadata: draft.optional_public_metadata,
          encryption_key: ipfsResult.encryptionKey
        });
      
      if (mintedInsertError) {
        throw new Error("Failed to record minted DPP: " + mintedInsertError.message);
      }
      
      // 9. Update the draft status to indicate it's been minted
      const { error: draftUpdateError } = await supabase
        .from("product_drafts")
        .update({ status: "minted" })
        .eq("id", draft.id);
      
      if (draftUpdateError) {
        console.error("Error updating draft status:", draftUpdateError);
        // Not critical, so just log it
      }
      
      toast.success("DPP successfully minted!");
      
      // 10. Redirect to view the minted DPP
      navigate(`/dashboard/view/${tokenId}`);
      
    } catch (error) {
      console.error("Error minting DPP:", error);
      toast.error(typeof error === "object" && error !== null ? 
        (error as Error).message || "Failed to mint DPP" : 
        "Failed to mint DPP");
    } finally {
      setIsMinting(false);
    }
  };

  return (
    <Card className="relative overflow-hidden">
      {/* Draft Banner */}
      <div className="absolute top-0 right-0 left-0 bg-yellow-500/20 backdrop-blur-sm p-2 text-center">
        <Badge variant="secondary" className="pointer-events-none">
          Draft
        </Badge>
      </div>

      <CardHeader className="pt-12">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-semibold">{draft.name}</h3>
            <p className="text-sm text-muted-foreground">
              {draft.product_category}
            </p>
          </div>
          {draft.product_image && (
            <img
              src={draft.product_image}
              alt={draft.name}
              className="w-16 h-16 object-cover rounded-md"
            />
          )}
        </div>
      </CardHeader>

      <CardContent>
        <div className="space-y-4">
          {draft.description && (
            <p className="text-sm text-muted-foreground line-clamp-2">
              {draft.description}
            </p>
          )}

          <div className="grid grid-cols-2 gap-2 text-sm">
            <div>
              <span className="text-muted-foreground">Progress:</span>{" "}
              <span className="font-medium">{Math.round((draft.last_step / 7) * 100)}%</span>
            </div>
            <div>
              <span className="text-muted-foreground">Last saved:</span>{" "}
              <span className="font-medium">
                {new Date(draft.last_auto_save).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="flex gap-2 mt-4">
            <Button
              variant="outline"
              className="flex-1"
              onClick={handleContinueEditing}
              disabled={isMinting}
            >
              <PenLine className="w-4 h-4 mr-2" />
              Continue Editing
            </Button>
            <Button 
              className="flex-1" 
              onClick={handleMint}
              disabled={isMinting}
            >
              {isMinting ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Minting...
                </>
              ) : (
                <>
                  <Coins className="w-4 h-4 mr-2" />
                  Mint DPP
                </>
              )}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
