import React, { useState, useEffect } from "react";
import { toast } from "sonner";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { ProductIdentitySection } from "@/components/create-dpp/sections/ProductIdentitySection";
import { ManufacturingForm } from "@/components/create-dpp/ManufacturingForm";
import { MaterialProvenanceForm } from "@/components/create-dpp/MaterialProvenanceForm";
import { SustainabilityForm } from "@/components/create-dpp/SustainabilityForm";
import { ComplianceForm } from "@/components/create-dpp/ComplianceForm";
import { LifecycleComplianceForm } from "@/components/create-dpp/LifecycleComplianceForm";
import { ReviewForm } from "@/components/create-dpp/ReviewForm";
import { Button } from "@/components/ui/button";
import { DPPFormData } from "@/types/dpp-form";
import { PreviewPanel } from "./PreviewPanel";
import { supabase } from "@/integrations/supabase/client";
import { useParams, useNavigate } from "react-router-dom";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { Json } from "@/integrations/supabase/types";
import { Save } from "lucide-react";
import { saveDppDraft, loadDppDraft } from "@/utils/dpp-draft-utils";

export function CreateLayout() {
  const { primaryWallet: user } = useDynamicContext();
  const { draftId } = useParams();
  const navigate = useNavigate();
  const [currentDraftId, setCurrentDraftId] = useState<string | null>(
    draftId || null
  );
  const [activeTab, setActiveTab] = useState("productIdentity");
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [formData, setFormData] = useState<DPPFormData>({
    metadata: {
      type: ["DigitalProductPassport", "VerifiableCredential"],
      context: [],
      id: "",
      issuer: {
        id: "",
        name: "",
        otherIdentifier: [],
        verificationKey: "",
      },
      validFrom: new Date(),
      granularityLevel: undefined, // Remove default value
      credentialSubject: "",
      dataCarrier: "",
      blockchainAddress: "",
      apiEndpoints: [],
      dataSchemaVersion: "",
      encryptionStandards: [],
      credentialStatus: {
        type: "",
        status: "active",
      },
      proof: {
        type: "",
        created: new Date(),
        proofPurpose: "assertionMethod",
        verificationMethod: "",
        proofValue: "",
      },
    },
    publicMetadata: {
      type: ["DigitalProductPassport", "VerifiableCredential"],
      context: [],
      id: "",
      issuer: {
        id: "",
        name: "",
        otherIdentifier: [],
        verificationKey: "",
      },
      validFrom: new Date(),
      granularityLevel: undefined, // Remove default value
      credentialSubject: "",
      dataCarrier: "",
      blockchainAddress: "",
      apiEndpoints: [],
      dataSchemaVersion: "",
      encryptionStandards: [],
      credentialStatus: {
        type: "",
        status: "active",
      },
      proof: {
        type: "",
        created: new Date(),
        proofPurpose: "assertionMethod",
        verificationMethod: "",
        proofValue: "",
      },
    },
    productBasics: {
      productName: "",
      productId: "",
      productCategory: [],
      idScheme: { id: "", name: "" },
      globalModelNumber: "",
      manufacturingCountry: "",
      productionDate: new Date(),
      expectedLifespan: 0,
      repairabilityIndex: 0,
      description: "",
      productDescription: "", // Added missing field
      serialNumber: "",
      batchNumber: "",
      productImage: "",
      dimensions: undefined,
      dataCarriers: ["QR"],
      releaseDate: new Date(),
    },
    manufacturing: {
      manufacturerName: "",
      facilityName: "",
      registeredId: "",
      manufacturingCountry: "",
      productionDate: new Date(),
      qualityCertifications: [],
      producedByParty: {
        id: "",
        name: "",
        registeredId: "",
        scheme: "", // Changed from idScheme to scheme
        value: "",
      },
      producedAtFacility: {
        id: "",
        name: "",
        registeredId: "",
        scheme: "", // Changed from idScheme to scheme
        value: "",
      },
    },
    manufacturingDetails: {
      manufacturerName: "",
      facilityName: "",
      registeredId: "",
      manufacturingCountry: "",
      productionDate: new Date(),
      qualityCertifications: [],
      producedByParty: {
        id: "",
        name: "",
        registeredId: "",
        scheme: "", // Changed from idScheme to scheme
        value: "",
      },
      producedAtFacility: {
        id: "",
        name: "",
        registeredId: "",
        scheme: "", // Changed from idScheme to scheme
        value: "",
      },
    },
    materials: [],
    sustainability: {
      carbonFootprint: 0,
      declaredUnit: "",
      operationalScope: "CradleToGate",
      primarySourcedRatio: 0,
      repairabilityIndex: 0,
      circularityScorecard: {
        materialCircularityIndicator: 0,
        recycledContent: 0,
        recyclabilityFraction: 0,
        declaredUnit: "",
        utilityFactor: 0,
      },
      emissionsScorecard: {
        carbonFootprint: 0,
        declaredUnit: "",
        operationalScope: "CradleToGate",
        primarySourcedRatio: 0,
      },
      energyEfficiencyClass: "",
      expectedLifespan: 0,
      waterScarcityFootprint: 0,
      biodiversityImpact: "",
      recyclingInstructions: "",
      takebackProgramDetails: "",
      recyclableContent: 0,
      reportingStandard: "",
    },
    compliance: {
      dueDiligenceDeclaration: {
        linkURL: "",
        linkName: "",
        linkType: "",
        hashDigest: "",
        hashMethod: "SHA-256",
        encryptionMethod: "none",
      },
      conformityClaims: [], // Changed from conformityClaim
      certifications: [],
      auditTrail: [],
    },
    lifecycle: {
      dueDiligenceDeclaration: {
        linkURL: "",
        linkName: "",
        linkType: "",
        hashDigest: "",
        hashMethod: "SHA-256",
        encryptionMethod: "none",
      },
      conformityClaims: [],
      traceabilityEvents: [], // Changed from traceabilityInformation
      tieredSupplierList: {
        suppliers: [],
      },
      transportationLogs: {
        // Changed from transportationLog
        events: [],
      },
      customsDocumentation: {
        declarations: [], // Added required property
      },
      serviceHistory: {
        // Changed from serviceHistoryLog
        services: [],
      },
      sparePartsCatalog: {
        parts: [],
      },
      disassemblyInstructions: {
        instructions: {
          linkURL: "",
          linkName: "",
          linkType: "",
          hashDigest: "",
          hashMethod: "SHA-256",
          encryptionMethod: "none",
        },
        disassemblyTime: 0,
        disassemblyScore: 0,
        tools: [],
        difficulty: "easy",
      },
      warrantyStatus: "active", // Changed from empty string to valid enum value
      usagePatterns: {},
    },
  });

  // Add validation function
  const validateFormData = (data: DPPFormData): boolean => {
    // Basic required fields validation
    if (!data.productBasics.productName || !data.productBasics.productId) {
      console.warn("Missing required product basics fields");
      return false;
    }

    // Validate metadata
    if (!data.metadata.validFrom || !data.metadata.id) {
      console.warn("Missing required metadata fields");
      return false;
    }

    return true;
  };

  // Load existing draft if draftId is provided
  useEffect(() => {
    const loadDraft = async () => {
      if (!draftId || !user?.walletPublicKey) return;

      try {
        // First get the user's profile ID from dynamic_user_info
        const { data: userInfo, error: userError } = await supabase
          .from("dynamic_user_info")
          .select("id")
          .eq("primary_wallet_address", user.walletPublicKey)
          .maybeSingle(); // Use maybeSingle instead of single

        if (userError) {
          console.error("Error fetching user profile:", userError);
          return;
        }

        // Check if we have a valid user profile
        if (!userInfo?.id) {
          console.warn("No user profile found, cannot load draft");
          return;
        }

        // Try to load the draft using our utility function
        const result = await loadDppDraft(draftId, userInfo.id);

        if (!result.success || !result.formData) {
          console.error("Error loading draft:", result.error);
          toast.error("Error loading draft");
          return;
        }

        // Update the form data with the loaded draft data
        setFormData(result.formData);
        console.log("Draft loaded successfully");
        toast.success("Draft loaded successfully");

        // Try to get the last_step to set the active tab
        try {
          // Also fetch the draft metadata to get the last_step
          const { data: draftData } = await supabase
            .from("dpp_drafts")
            .select("last_step, last_auto_save")
            .eq("id", draftId)
            .single();

          if (draftData?.last_auto_save) {
            setLastSaved(new Date(draftData.last_auto_save));
          }

          if (draftData?.last_step) {
            const stepToTabMap: Record<number, string> = {
              1: "productIdentity",
              2: "manufacturing",
              3: "materials",
              4: "sustainability",
              5: "compliance",
              6: "lifecycle",
              7: "review",
            };
            setActiveTab(
              stepToTabMap[draftData.last_step] || "productIdentity"
            );
          }
        } catch (error) {
          console.warn("Could not fetch draft metadata, using default tab");
          // Default to the product identity tab if we can't determine the last step
          setActiveTab("productIdentity");
        }
      } catch (error) {
        console.error("Error in draft loading process:", error);
        toast.error("Error loading draft");
      }
    };

    loadDraft();
  }, [draftId, user?.walletPublicKey]);

  // Helper function to check if user has a connected wallet
  // Also checks localStorage for persistent wallet connection state
  const isWalletConnected = () => {
    // Check Dynamic user object first
    const dynamicWalletConnected =
      !!user?.walletPublicKey || !!user?.verifiedCredentials?.[0]?.address;

    // If Dynamic shows connected, return true
    if (dynamicWalletConnected) return true;

    // Fall back to localStorage if Dynamic context doesn't show the wallet
    const localStorageWalletConnected =
      localStorage.getItem("walletConnected") === "true";

    return dynamicWalletConnected || localStorageWalletConnected;
  };

  // Get the wallet address from user object (with localStorage fallback)
  const getWalletAddress = () => {
    // Try to get from Dynamic user object first
    const dynamicWalletAddress =
      user?.walletPublicKey || user?.verifiedCredentials?.[0]?.address;
    if (dynamicWalletAddress) return dynamicWalletAddress;

    // Fall back to localStorage if not available in Dynamic context
    const localStorageWalletAddress = localStorage.getItem("walletAddress");

    return dynamicWalletAddress || localStorageWalletAddress || "";
  };

  const handleSaveDraft = async () => {
    if (!isWalletConnected()) {
      toast.error("Please connect your wallet to save a draft");

      // Try to open the Dynamic wallet modal if available
      try {
        if (
          window.Dynamic &&
          typeof window.Dynamic.showAuthFlow === "function"
        ) {
          window.Dynamic.showAuthFlow();
        }
      } catch (err) {
        console.error("Could not open Dynamic wallet modal:", err);
      }
      return;
    }

    try {
      setIsSaving(true);

      // First get the user's profile ID from dynamic_user_info
      try {
        // Try to get a single user profile first - use our wallet address helper to get the address
        const walletAddress = getWalletAddress();
        console.log("Using wallet address for profile lookup:", walletAddress);

        const { data: userInfo, error: userError } = await supabase
          .from("dynamic_user_info")
          .select("id")
          .eq("primary_wallet_address", walletAddress)
          .maybeSingle(); // Use maybeSingle instead of single to avoid errors if no rows found

        if (userError) {
          console.error("Error fetching user profile:", userError);
          toast.error("Error finding user profile");
          return;
        }

        // If no user profile found, create one or use a fallback
        if (!userInfo?.id) {
          console.log("No user profile found, continuing with default profile");

          // Try to create a new user profile
          try {
            const { data: newUser, error: createError } = await supabase
              .from("dynamic_user_info")
              .insert({
                primary_wallet_address: getWalletAddress(),
                created_at: new Date().toISOString(),
                // Add any other required fields here
              })
              .select("id")
              .single();

            if (createError) {
              console.error("Error creating user profile:", createError);
              // Continue with a fallback ID
            } else if (newUser?.id) {
              console.log("Created new user profile with ID:", newUser.id);
              // Save draft with the new user ID
              await saveDraftWithUserId(newUser.id);
              return;
            }
          } catch (createErr) {
            console.error("Failed to create user profile:", createErr);
            // Continue with fallback
          }

          // If we couldn't create a profile, use a fallback approach
          toast.warning(
            "Using temporary profile - some features may be limited"
          );
          // Use wallet address as fallback ID
          await saveDraftWithFallbackId(getWalletAddress());
          return;
        }

        // If we found a valid user profile, use it
        await saveDraftWithUserId(userInfo.id);
      } catch (error) {
        console.error("Unexpected error fetching user profile:", error);
        toast.error("Unexpected error finding user profile");
        return;
      }
    } catch (error) {
      console.error("Error in save draft process:", error);
      toast.error("Error saving draft");
    } finally {
      setIsSaving(false);
    }
  };

  // Helper function to save draft with a valid user ID
  const saveDraftWithUserId = async (userId: string) => {
    const result = await saveDppDraft(
      formData,
      userId,
      currentDraftId || undefined,
      activeTab
    );

    if (result.success) {
      if (result.draftId && !currentDraftId) {
        setCurrentDraftId(result.draftId);
        const currentUrl = `/dashboard/create/${result.draftId}`;
        if (window.location.pathname !== currentUrl) {
          navigate(currentUrl, { replace: true });
        }
      }
      setLastSaved(new Date());
      toast.success("Draft saved successfully");
    } else {
      console.error("Error saving draft:", result.error);
      toast.error("Error saving draft");
    }
  };

  // Helper function when we need to use a fallback ID
  const saveDraftWithFallbackId = async (fallbackId: string) => {
    // For fallback we convert the wallet address to a string to use as ID
    const result = await saveDppDraft(
      formData,
      fallbackId,
      currentDraftId || undefined,
      activeTab
    );

    if (result.success) {
      if (result.draftId && !currentDraftId) {
        setCurrentDraftId(result.draftId);
        const currentUrl = `/dashboard/create/${result.draftId}`;
        if (window.location.pathname !== currentUrl) {
          navigate(currentUrl, { replace: true });
        }
      }
      setLastSaved(new Date());
      toast.success("Draft saved successfully");
    } else {
      console.error("Error saving draft with fallback ID:", result.error);
      toast.error("Error saving draft");
    }
  };

  const processFormSubmission = (userId: string) => {
    const isValid = validateFormData(formData);
    if (!isValid) {
      console.warn("Form data validation failed, saving as incomplete draft");
    }

    // Clear local storage data on successful draft save
    try {
      if (localStorage.getItem("dpp_form_data")) {
        console.log("Clearing local storage as data is now saved to server");
      }
    } catch (error) {
      console.error("Error accessing local storage:", error);
    }

    const productCategory = Array.isArray(
      formData.productBasics.productCategory
    )
      ? formData.productBasics.productCategory[0]?.name || "uncategorized"
      : "uncategorized";

    const draftData = {
      profile_id: userId, // Use the UUID from dynamic_user_info
      form_data: formData as unknown as Json,
      name: formData.productBasics.productName || "Untitled DPP",
      description: formData.productBasics.productDescription || "",
      product_category: productCategory,
      product_image: formData.productBasics.productImage || "",
      sku: formData.productBasics.serialNumber || "",
      gtin: formData.productBasics.productId || "",
      production_date: formData.productBasics.productionDate.toISOString(),
      country_of_production: formData.productBasics.manufacturingCountry || "",
      status: "draft",
      last_step:
        activeTab === "review"
          ? 7
          : activeTab === "lifecycle"
          ? 6
          : activeTab === "compliance"
          ? 5
          : activeTab === "sustainability"
          ? 4
          : activeTab === "materials"
          ? 3
          : activeTab === "manufacturing"
          ? 2
          : 1,
      last_auto_save: new Date().toISOString(),
      optional_public_metadata: {
        manufacturerName: formData.manufacturingDetails.manufacturerName,
        facilityName: formData.manufacturingDetails.facilityName,
        materialCount: formData.materials.length,
        sustainabilityScore:
          formData.sustainability.circularityScorecard
            .materialCircularityIndicator,
        complianceCertifications:
          formData.compliance.certifications?.length || 0,
      } as Json,
    };

    if (currentDraftId) {
      supabase
        .from("product_drafts")
        .update(draftData)
        .eq("id", currentDraftId)
        .eq("profile_id", userId)
        .then(({ error }) => {
          if (error) throw error;
        });
    } else {
      supabase
        .from("product_drafts")
        .insert(draftData)
        .select()
        .single()
        .then(({ data, error }) => {
          if (error) throw error;
          if (data) {
            setCurrentDraftId(data.id);
            navigate(`/dashboard/create/${data.id}`, { replace: true });
          }
        });
    }

    setLastSaved(new Date());
    toast.success("Draft saved successfully");
  };

  const handleDataChange = (section: keyof DPPFormData, data: any) => {
    setFormData((prev) => ({ ...prev, [section]: data }));
    setHasChanges(true);
  };

  // Local storage saving functionality
  // Save form data to local storage whenever it changes
  useEffect(() => {
    if (hasChanges) {
      const timer = setTimeout(() => {
        console.log("Saving form data to local storage...");
        // Save form data to local storage with current tab information
        try {
          localStorage.setItem(
            "dpp_form_data",
            JSON.stringify({
              formData: formData,
              lastSaved: new Date().toISOString(),
              activeTab: activeTab,
              draftId: currentDraftId,
            })
          );
          console.log("Form data saved to local storage");
          setHasChanges(false);
        } catch (error) {
          console.error("Error saving to local storage:", error);
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [formData, hasChanges, activeTab]);

  // Load form data from local storage on component mount
  useEffect(() => {
    // Only load from local storage if no draft ID is provided
    // This ensures we prioritize server-stored drafts when available
    if (!draftId) {
      try {
        const savedData = localStorage.getItem("dpp_form_data");
        if (savedData) {
          const parsedData = JSON.parse(savedData);
          console.log("Found saved form data in local storage");

          // If the saved data has a draft ID and it's different from our current one,
          // we'll navigate to that draft instead of loading the local data
          if (parsedData.draftId && parsedData.draftId !== currentDraftId) {
            console.log("Found saved draft ID, navigating to that draft");
            navigate(`/dashboard/create/${parsedData.draftId}`, {
              replace: true,
            });
            return;
          }

          // Otherwise, load the saved form data
          setFormData(parsedData.formData);
          if (parsedData.activeTab) {
            setActiveTab(parsedData.activeTab);
          }
          if (parsedData.lastSaved) {
            setLastSaved(new Date(parsedData.lastSaved));
          }

          // Fix toast call to use proper format with string instead of object
          toast.success("Progress Restored", {
            description:
              "Your previous progress has been restored from local storage.",
          });
        }
      } catch (error) {
        console.error("Error loading from local storage:", error);
      }
    }
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-8">
      <Tabs
        defaultValue="productIdentity"
        className="space-y-8"
        value={activeTab}
        onValueChange={setActiveTab}
      >
        <div className="flex flex-col">
          {/* Header with tabs and save button */}
          <div className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-md rounded-2xl p-4 mb-8">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-medium text-[#403E43] mb-4">
                Create Digital Product Passport
              </h2>
              <div className="flex items-center gap-4">
                <Button
                  onClick={handleSaveDraft}
                  variant="outline"
                  disabled={isSaving}
                  className="gap-2 h-10 px-4 bg-white/60 hover:bg-white/80 text-[#403E43] border border-[#F1F0FB]"
                >
                  <Save className="h-4 w-4" />
                  {isSaving ? "Saving..." : "Save Draft"}
                </Button>
                {lastSaved && (
                  <span className="text-sm text-[#8E9196]">
                    Last saved: {lastSaved.toLocaleTimeString()}
                  </span>
                )}
              </div>
            </div>

            <TabsList className="w-full grid grid-cols-7 bg-white/40 p-1 rounded-xl">
              <TabsTrigger
                value="productIdentity"
                className="rounded-lg data-[state=active]:bg-[#33C3F0] data-[state=active]:text-white"
              >
                Product Identity
              </TabsTrigger>
              <TabsTrigger
                value="manufacturing"
                className="rounded-lg data-[state=active]:bg-[#33C3F0] data-[state=active]:text-white"
              >
                Manufacturing
              </TabsTrigger>
              <TabsTrigger
                value="materials"
                className="rounded-lg data-[state=active]:bg-[#33C3F0] data-[state=active]:text-white"
              >
                Materials
              </TabsTrigger>
              <TabsTrigger
                value="sustainability"
                className="rounded-lg data-[state=active]:bg-[#33C3F0] data-[state=active]:text-white"
              >
                Sustainability
              </TabsTrigger>
              <TabsTrigger
                value="compliance"
                className="rounded-lg data-[state=active]:bg-[#33C3F0] data-[state=active]:text-white"
              >
                Compliance
              </TabsTrigger>
              <TabsTrigger
                value="lifecycle"
                className="rounded-lg data-[state=active]:bg-[#33C3F0] data-[state=active]:text-white"
              >
                Lifecycle
              </TabsTrigger>
              <TabsTrigger
                value="review"
                className="rounded-lg data-[state=active]:bg-[#33C3F0] data-[state=active]:text-white"
              >
                Review
              </TabsTrigger>
            </TabsList>
          </div>

          {/* Main content area */}
          <div className="grid grid-cols-[2fr,1fr] gap-8 mb-8">
            <div className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-md rounded-2xl p-8">
              <TabsContent value="productIdentity" className="mt-0">
                <ProductIdentitySection
                  data={formData.productBasics}
                  metadata={formData.publicMetadata}
                  onChange={(field, value) =>
                    handleDataChange("productBasics", {
                      ...formData.productBasics,
                      [field]: value,
                    })
                  }
                  onMetadataChange={(field, value) =>
                    handleDataChange("publicMetadata", {
                      ...formData.publicMetadata,
                      [field]: value,
                    })
                  }
                />
              </TabsContent>
              <TabsContent value="manufacturing" className="mt-0">
                <ManufacturingForm
                  initialData={formData.manufacturingDetails}
                  onDataChange={(data) =>
                    handleDataChange("manufacturingDetails", data)
                  }
                />
              </TabsContent>
              <TabsContent value="materials" className="mt-0">
                <MaterialProvenanceForm
                  initialData={{
                    materials: formData.materials,
                    totalRecycledContent: 0,
                    totalBiobasedContent: 0,
                    msdsLink: {
                      linkURL: "",
                      linkName: "",
                      linkType: "",
                      hashDigest: "",
                      hashMethod: "SHA-256",
                      encryptionMethod: "none",
                    },
                    dueDiligenceDeclaration: {
                      linkURL: "",
                      linkName: "",
                      linkType: "",
                      hashDigest: "",
                      hashMethod: "SHA-256",
                      encryptionMethod: "none",
                    },
                  }}
                  onDataChange={(data) =>
                    handleDataChange("materials", data.materials)
                  }
                />
              </TabsContent>
              <TabsContent value="sustainability" className="mt-0">
                <SustainabilityForm
                  initialData={formData.sustainability}
                  onDataChange={(data) =>
                    handleDataChange("sustainability", data)
                  }
                />
              </TabsContent>
              <TabsContent value="compliance" className="mt-0">
                <ComplianceForm
                  initialData={formData.compliance}
                  onDataChange={(data) => handleDataChange("compliance", data)}
                />
              </TabsContent>
              <TabsContent value="lifecycle" className="mt-0">
                <LifecycleComplianceForm
                  initialData={formData.lifecycle}
                  onDataChange={(data) => handleDataChange("lifecycle", data)}
                />
              </TabsContent>
              <TabsContent value="review" className="mt-0">
                <ReviewForm
                  formData={formData}
                  onDataChange={handleDataChange}
                />
                <div className="mt-8 flex justify-end">
                  <Button className="h-12 px-8 bg-[#33C3F0] hover:bg-[#33C3F0]/90 text-white rounded-lg">
                    Submit DPP
                  </Button>
                </div>
              </TabsContent>
            </div>

            <div className="relative">
              <div className="sticky top-8">
                <div className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-md rounded-2xl p-6">
                  <h3 className="text-lg font-medium text-[#403E43] mb-4">
                    Preview
                  </h3>
                  <PreviewPanel formData={formData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
}
