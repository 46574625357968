import { ethers } from 'ethers';
import { keccak256 as ethersKeccak256, toUtf8Bytes } from "ethers";

// Use environment variable for contract address
export const NFT_CONTRACT_ADDRESS = import.meta.env.VITE_NFT_CONTRACT_ADDRESS || "";
if (!NFT_CONTRACT_ADDRESS) {
  console.warn("NFT_CONTRACT_ADDRESS not configured. Set VITE_NFT_CONTRACT_ADDRESS in your environment variables.");
}

export const NFT_ABI = [
  "function mint(address to, string memory tokenURI, bytes32 dataHash) public returns (uint256)",
  "function tokenURI(uint256 tokenId) public view returns (string memory)",
  "function ownerOf(uint256 tokenId) public view returns (address)",
  "function getProductHash(uint256 tokenId) public view returns (bytes32)",
];

export const getProvider = () => {
  if (typeof window.ethereum !== 'undefined') {
    return new ethers.BrowserProvider(window.ethereum);
  }
  throw new Error('Please install MetaMask or another Web3 wallet');
};

export const hashProductData = (product: Record<string, unknown>) => {
  // Create a deterministic string representation of critical product data
  const criticalData = JSON.stringify({
    productId: product.productId,
    name: product.name,
    serialNumber: product.serialNumber,
    manufacturingData: {
      date: product.productionDate,
      company: product.companyName,
      id: product.registeredCompanyId
    },
    materials: product.materials
  });
  
  // Hash the data using keccak256
  return ethersKeccak256(toUtf8Bytes(criticalData));
};

export const getContract = async () => {
  const provider = getProvider();
  const signer = await provider.getSigner();
  return new ethers.Contract(NFT_CONTRACT_ADDRESS, NFT_ABI, signer);
};