import React, { useState, useCallback } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Classification, Dimension, DPPPublicMetadata, IdentifierScheme, Link, Measure, ProductBasicsFormData, SecureLink } from "@/types/dpp-form";
import { useDropzone } from 'react-dropzone';
import { cn } from "@/lib/utils";
import { Upload, Plus, X, Calendar, Factory, Star, Package2, TagIcon } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
interface ProductIdentitySectionProps {
  data: ProductBasicsFormData;
  metadata: DPPPublicMetadata;
  onChange: (field: keyof ProductBasicsFormData, value: any) => void;
  onMetadataChange: (field: keyof DPPPublicMetadata, value: any) => void;
}
export function ProductIdentitySection({
  data,
  metadata,
  onChange,
  onMetadataChange
}: ProductIdentitySectionProps) {
  const [previewUrl, setPreviewUrl] = useState<string>(data.productImage || '');
  const uploadToSupabase = async (file: File) => {
    const fileExt = file.name.split('.').pop();
    const filePath = `${crypto.randomUUID()}.${fileExt}`;
    const {
      data: uploadData,
      error
    } = await supabase.storage.from('product_images').upload(filePath, file);
    if (error) {
      console.error('Error uploading to Supabase:', error);
      return null;
    }
    const {
      data: {
        publicUrl
      }
    } = supabase.storage.from('product_images').getPublicUrl(filePath);
    return publicUrl;
  };
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      const publicUrl = await uploadToSupabase(file);
      if (publicUrl) {
        onChange('productImage', publicUrl);
      }
    }
  }, [onChange]);
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif']
    },
    maxFiles: 1
  });
  const handleGranularityChange = (value: "item" | "batch" | "model") => {
    onMetadataChange('granularityLevel', value);

    // Clear other identification fields when switching granularity
    onChange('serialNumber', '');
    onChange('batchNumber', '');
    onChange('globalModelNumber', '');
  };

  // Only show identification fields if granularity level is selected
  const shouldShowIdentificationField = (field: "item" | "batch" | "model") => {
    return metadata.granularityLevel === field;
  };
  const [classification, setClassification] = useState<Classification>({
    id: '',
    code: '',
    name: '',
    schemeID: '',
    schemeName: ''
  });
  const [newCategory, setNewCategory] = useState<Classification>({
    id: '',
    code: '',
    name: '',
    schemeID: '',
    schemeName: ''
  });
  const [dimension, setDimension] = useState<Dimension>({
    weight: {
      value: 0,
      unit: 'KGM'
    },
    length: {
      value: 0,
      unit: 'MMT'
    },
    width: {
      value: 0,
      unit: 'MMT'
    },
    height: {
      value: 0,
      unit: 'MMT'
    },
    volume: {
      value: 0,
      unit: 'LTR'
    }
  });
  const handleAddCategory = () => {
    const currentCategories = data.productCategory || [];
    onChange('productCategory', [...currentCategories, newCategory]);
    setNewCategory({
      id: '',
      code: '',
      name: '',
      schemeID: '',
      schemeName: ''
    });
  };
  const handleDimensionChange = (key: keyof Dimension, measure: Measure) => {
    const newDimension = {
      ...dimension,
      [key]: measure
    };
    setDimension(newDimension);
    onChange('dimensions', newDimension);
  };
  const [newLink, setNewLink] = useState<SecureLink>({
    linkURL: '',
    linkName: '',
    linkType: '',
    hashDigest: '',
    hashMethod: 'SHA-256',
    encryptionMethod: 'none'
  });
  const handleAddLink = () => {
    if (newLink.linkURL && newLink.linkName) {
      const currentLinks = data.furtherInformation || [];
      onChange('furtherInformation', [...currentLinks, newLink]);
      setNewLink({
        linkURL: '',
        linkName: '',
        linkType: '',
        hashDigest: '',
        hashMethod: 'SHA-256',
        encryptionMethod: 'none'
      });
    }
  };
  const handleRemoveLink = (index: number) => {
    const currentLinks = data.furtherInformation || [];
    onChange('furtherInformation', currentLinks.filter((_, i) => i !== index));
  };
  return (
    <div className="space-y-8">
      {/* Section 1: Visual Representation */}
      <div className="space-y-4">
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-semibold">Product Visual</h3>
            <Label>Product Image</Label>
            <div {...getRootProps()} className={cn("border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors mt-2", isDragActive ? "border-primary bg-muted/50" : "border-muted-foreground/25", previewUrl && "border-solid")}>
              <input {...getInputProps()} />
              {previewUrl ? <div className="relative aspect-video">
                  <img src={previewUrl} alt="Product preview" className="rounded-md object-cover w-full h-full" />
                  <button onClick={e => {
                e.stopPropagation();
                setPreviewUrl('');
                onChange('productImage', '');
              }} className="absolute top-2 right-2 bg-destructive text-destructive-foreground p-1 rounded-full hover:bg-destructive/90">
                    ×
                  </button>
                </div> : <div className="flex flex-col items-center justify-center gap-2 text-muted-foreground">
                  <Upload className="w-8 h-8" />
                  <p>Drag & drop a product image here, or click to select one</p>
                  <p className="text-sm">Supports: JPG, PNG, GIF</p>
                </div>}
            </div>
          </div>
        </div>
      </div>

      <Separator />
        
        {/* Section 2: Essential Information */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Basic Information</h3>
          
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="productName">Product Name*</Label>
              <Input id="productName" value={data.productName} onChange={e => onChange('productName', e.target.value)} required />
            </div>
            <div className="space-y-2">
              <Label>Granularity Level*</Label>
              <Select onValueChange={handleGranularityChange}>
                <SelectTrigger>
                  <SelectValue placeholder="Select level" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="item">Item</SelectItem>
                  <SelectItem value="batch">Batch</SelectItem>
                  <SelectItem value="model">Model</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Identification Section */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="productId">Product ID*</Label>
              <Input id="productId" value={data.productId} onChange={e => onChange('productId', e.target.value)} placeholder="Enter GS1 GTIN, ISO ID, or internal ID" required />
            </div>
            <div className="space-y-2">
              <Label>ID Scheme*</Label>
              <Select value={data.idScheme?.name} onValueChange={value => onChange('idScheme', {
              id: value,
              name: value
            })}>
                <SelectTrigger>
                  <SelectValue placeholder="Select ID scheme" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="GS1">GS1</SelectItem>
                  <SelectItem value="ISO_15459">ISO 15459</SelectItem>
                  <SelectItem value="INTERNAL">Internal ID</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Granularity-specific identification */}
          {shouldShowIdentificationField('item') && <div className="space-y-2">
              <Label htmlFor="serialNumber">Serial Number*</Label>
              <Input id="serialNumber" value={data.serialNumber} onChange={e => onChange('serialNumber', e.target.value)} required />
            </div>}
          
          {shouldShowIdentificationField('batch') && <div className="space-y-2">
              <Label htmlFor="batchNumber">Batch Number*</Label>
              <Input id="batchNumber" value={data.batchNumber} onChange={e => onChange('batchNumber', e.target.value)} required />
            </div>}
          
          {shouldShowIdentificationField('model') && <div className="space-y-2">
              <Label htmlFor="globalModelNumber">Global Model Number*</Label>
              <Input id="globalModelNumber" value={data.globalModelNumber} onChange={e => onChange('globalModelNumber', e.target.value)} required />
            </div>}

          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <textarea id="description" className="w-full p-2 border rounded-md min-h-[100px]" value={data.description} onChange={e => onChange('description', e.target.value)} placeholder="Provide a detailed description of the product..." />
          </div>
        </div>

      <Separator />

      {/* Section 3: Categories and Specifications */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Categories and Specifications</h3>
        
        {/* Product Categories */}
        <div className="space-y-4">
            <Label>Product Categories</Label>
            <div className="grid grid-cols-2 gap-4">
              <Input placeholder="Category Name" value={newCategory.name} onChange={e => setNewCategory({
              ...newCategory,
              name: e.target.value
            })} />
              <Input placeholder="Category Code" value={newCategory.code} onChange={e => setNewCategory({
              ...newCategory,
              code: e.target.value
            })} />
              <Input placeholder="Scheme ID" value={newCategory.schemeID} onChange={e => setNewCategory({
              ...newCategory,
              schemeID: e.target.value
            })} />
              <Button onClick={handleAddCategory} className="w-full" disabled={!newCategory.name || !newCategory.code}>
                <Plus className="w-4 h-4 mr-2" />
                Add Category
              </Button>
            </div>
            <div className="mt-2">
              {data.productCategory?.map((cat, index) => <div key={index} className="flex items-center justify-between p-2 bg-muted rounded-md mb-2">
                  <span>{cat.name} ({cat.code})</span>
                  <Button variant="ghost" size="sm" onClick={() => onChange('productCategory', data.productCategory?.filter((_, i) => i !== index) || [])}>
                    <X className="h-4 w-4" />
                  </Button>
                </div>)}
            </div>
          </div>

          {/* Dimensions */}
          <div className="space-y-4">
            <Label>Product Dimensions</Label>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(dimension).map(([key, measure]) => <div key={key} className="space-y-2">
                  <Label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</Label>
                  <div className="flex gap-2">
                    <Input type="number" value={measure?.value || ''} onChange={e => handleDimensionChange(key as keyof Dimension, {
                  value: parseFloat(e.target.value) || 0,
                  unit: measure?.unit || 'KGM'
                })} placeholder="0" />
                    <Select value={measure?.unit} onValueChange={value => handleDimensionChange(key as keyof Dimension, {
                  value: measure?.value || 0,
                  unit: value
                })}>
                      <SelectTrigger className="w-[100px]">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="KGM">kg</SelectItem>
                        <SelectItem value="MMT">mm</SelectItem>
                        <SelectItem value="LTR">L</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>)}
            </div>
          </div>
        </div>

      {/* Section 4: Additional Information Links */}
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Additional Information</h3>
        <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <Input placeholder="Link Name" value={newLink.linkName} onChange={e => setNewLink({
              ...newLink,
              linkName: e.target.value
            })} />
              <Input placeholder="URL" value={newLink.linkURL} onChange={e => setNewLink({
              ...newLink,
              linkURL: e.target.value
            })} />
            </div>
            <Select value={newLink.linkType} onValueChange={value => setNewLink({
            ...newLink,
            linkType: value
          })}>
              <SelectTrigger>
                <SelectValue placeholder="Select link type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="manual">Manual</SelectItem>
                <SelectItem value="safety_sheet">Safety Sheet</SelectItem>
                <SelectItem value="website">Website</SelectItem>
                <SelectItem value="certification">Certification</SelectItem>
              </SelectContent>
            </Select>
            <Button onClick={handleAddLink} className="w-full" disabled={!newLink.linkURL || !newLink.linkName}>
              <Plus className="w-4 h-4 mr-2" />
              Add Link
            </Button>
          </div>
          
          <div className="space-y-2">
            {(data.furtherInformation || []).map((link, index) => <div key={index} className="flex items-center justify-between p-2 rounded-md bg-secondary">
                <div>
                  <span className="font-medium">{link.linkName}</span>
                  <span className="text-sm text-muted-foreground ml-2">({link.linkType})</span>
                </div>
                <Button variant="ghost" size="sm" onClick={() => handleRemoveLink(index)}>
                  <X className="h-4 w-4" />
                </Button>
              </div>)}
          </div>
        </div>
    </div>
  );
}