
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Card } from "@/components/ui/card";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Calendar as CalendarIcon } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Separator } from "@/components/ui/separator";
import { ManufacturingDetailsFormData } from "@/types/dpp-form";
import { MapPicker } from "./MapPicker";

interface ManufacturingFormProps {
  onDataChange: (data: Partial<ManufacturingDetailsFormData>) => void;
  initialData?: Partial<ManufacturingDetailsFormData>;
}

export function ManufacturingForm({ onDataChange, initialData }: ManufacturingFormProps) {
  const [formData, setFormData] = useState<Partial<ManufacturingDetailsFormData>>(initialData || {});
  const [newCertification, setNewCertification] = useState("");
  const [newCertificationLink, setNewCertificationLink] = useState("");

  const handleChange = (field: keyof ManufacturingDetailsFormData, value: any) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    onDataChange(updatedData);
  };

  const addCertification = () => {
    if (newCertification.trim()) {
      const certificationEntry = newCertificationLink.trim() 
        ? `${newCertification.trim()}|${newCertificationLink.trim()}`
        : newCertification.trim();
      
      const currentCertifications = formData.qualityCertifications || [];
      handleChange('qualityCertifications', [...currentCertifications, certificationEntry]);
      setNewCertification("");
      setNewCertificationLink("");
    }
  };

  const removeCertification = (index: number) => {
    const currentCertifications = formData.qualityCertifications || [];
    handleChange('qualityCertifications', 
      currentCertifications.filter((_, i) => i !== index)
    );
  };

  const handleLocationSelect = (latitude: string, longitude: string) => {
    handleChange('facilityLocation', { latitude, longitude });
  };

  return (
    <div className="space-y-8">
      {/* Company Information */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Company Information</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="manufacturerName">Manufacturer Name*</Label>
            <Input 
              id="manufacturerName" 
              placeholder="Enter manufacturer name"
              value={formData.manufacturerName || ''}
              onChange={(e) => handleChange('manufacturerName', e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="facilityName">Facility Name*</Label>
            <Input 
              id="facilityName" 
              placeholder="Enter facility name"
              value={formData.facilityName || ''}
              onChange={(e) => handleChange('facilityName', e.target.value)}
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="registeredId">Business ID*</Label>
            <Input 
              id="registeredId" 
              placeholder="Enter company's registered business ID"
              value={formData.registeredId || ''}
              onChange={(e) => handleChange('registeredId', e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="manufacturingCountry">Manufacturing Country*</Label>
            <Input 
              id="manufacturingCountry" 
              placeholder="Enter manufacturing country"
              value={formData.manufacturingCountry || ''}
              onChange={(e) => handleChange('manufacturingCountry', e.target.value)}
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="facilityType">Facility Type</Label>
            <Select
              value={formData.facilityType || ''}
              onValueChange={(value) => handleChange('facilityType', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select facility type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="main">Main Factory</SelectItem>
                <SelectItem value="subsidiary">Subsidiary</SelectItem>
                <SelectItem value="partner">Partner Facility</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="productionCapacity">Production Capacity</Label>
            <Input 
              id="productionCapacity"
              type="number"
              placeholder="Units per day"
              value={formData.productionCapacity || ''}
              onChange={(e) => handleChange('productionCapacity', e.target.value)}
            />
          </div>
        </div>
      </div>

      <Separator />

      {/* Production Information */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Production Information</h3>
        <div className="space-y-2">
          <Label>Production Date*</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !formData.productionDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {formData.productionDate ? format(formData.productionDate, "PPP") : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={formData.productionDate}
                onSelect={(date) => handleChange('productionDate', date)}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="space-y-2">
          <Label>Manufacturing Process Type</Label>
          <Select
            value={formData.manufacturingProcessType || ''}
            onValueChange={(value) => handleChange('manufacturingProcessType', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select process type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="assembly">Assembly Line</SelectItem>
              <SelectItem value="batch">Batch Production</SelectItem>
              <SelectItem value="continuous">Continuous Flow</SelectItem>
              <SelectItem value="custom">Custom Process</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="manufacturingProcess">Manufacturing Process Details</Label>
          <Textarea 
            id="manufacturingProcess" 
            placeholder="Describe the manufacturing process, quality control methods, and key production steps"
            className="min-h-[100px]"
            value={formData.manufacturingProcess || ''}
            onChange={(e) => handleChange('manufacturingProcess', e.target.value)}
          />
        </div>

        <Separator className="my-6" />

        <div className="space-y-2">
          <Label className="text-base">Facility Location</Label>
          <MapPicker
            onLocationSelect={handleLocationSelect}
            initialLatitude={formData.facilityLocation?.latitude}
            initialLongitude={formData.facilityLocation?.longitude}
          />
        </div>
      </div>

      <Separator />

      {/* Quality Certifications */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Quality Certifications</h3>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <Input
              placeholder="Certification name"
              value={newCertification}
              onChange={(e) => setNewCertification(e.target.value)}
            />
            <Input
              placeholder="Certification link (optional)"
              value={newCertificationLink}
              onChange={(e) => setNewCertificationLink(e.target.value)}
            />
          </div>
          <Button 
            onClick={addCertification}
            className="w-full"
          >
            Add Certification
          </Button>
        </div>
        
        <div className="space-y-2">
          {(formData.qualityCertifications || []).map((cert, index) => {
            const [name, link] = cert.split('|');
            return (
              <div 
                key={index}
                className="flex items-center justify-between p-2 rounded-md bg-secondary"
              >
                <span>
                  {link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                      {name}
                    </a>
                  ) : (
                    name
                  )}
                </span>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeCertification(index)}
                >
                  Remove
                </Button>
              </div>
            );
          })}
        </div>
      </div>

      <Separator />

      {/* Supply Chain */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold">Supply Chain Information</h3>
        <div className="space-y-2">
          <Label>Supply Chain Transparency Score</Label>
          <Select
            value={formData.supplyChainTransparencyScore || ''}
            onValueChange={(value) => handleChange('supplyChainTransparencyScore', value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select transparency level" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="high">{'>'}90% (High)</SelectItem>
              <SelectItem value="medium">60-90% (Medium)</SelectItem>
              <SelectItem value="low">{'<'}60% (Low)</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="supplierInformation">Tier 1 Supplier Information</Label>
          <Textarea 
            id="supplierInformation" 
            placeholder="List key suppliers and their roles in the manufacturing process"
            className="min-h-[100px]"
            value={formData.supplierInformation || ''}
            onChange={(e) => handleChange('supplierInformation', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
