
import { useState, useEffect } from 'react';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { isZeroDevConnector } from '@dynamic-labs/ethereum-aa';
import { encodeFunctionData, toHex } from 'viem';
import { useToast } from '@/hooks/use-toast';
import { generateDid } from '@/utils/did';
import { getContractAddresses } from '@/utils/contractAddresses'; 
import { uploadToIPFS } from '@/utils/ipfs';

export interface VerifiableCredential {
  context: string[];
  id: string;
  issuer: string;
  issuerDid: string;
  validFrom: number;
  validUntil: number;
  granularityLevel: string;
  dataCarrier: string;
  apiEndpoints: string[];
  dataSchemaVersion: string;
  encryptionStandards: string[];
}

export interface ProductIdentity {
  productId: string;
  idScheme: string;
  name: string;
  productCategory: string[];
  releaseDate: number;
  globalModelNumber: string;
  manufacturingCountry: string;
  productionDate: number;
}

export interface DPPMetadata {
  basicInfo: {
    productId: string;
    idScheme: string;
    productName: string;
    manufacturerName: string;
    category: string;
    releaseDate: string;
    modelNumber: string;
    granularityLevel: string;
  };
  manufacturing: {
    country: string;
    productionDate: string;
    facility: string;
    process: string;
  };
  materials: {
    composition: Array<{
      name: string;
      percentage: number;
      isHazardous: boolean;
      recycledContent: number;
    }>;
    hasHazardousMaterials: boolean;
  };
  sustainability: {
    carbonFootprint: number;
    waterFootprint: number;
    repairabilityIndex: number;
    circularityScore: number;
  };
  certifications: {
    list: string[];
    evidence: string[];
  };
  compliance: {
    standards: string[];
    declarations: string[];
  };
}

export interface TransactionState {
  isMinting: boolean;
  hasMinted: boolean;
  error: Error | null;
  transactionHash: string | null;
}

// Your contract ABI - we'll need to import this from your contracts
import { DPPContractABI } from '@/abis/DPPContract';

// Explicitly define minimal interface for AA provider to avoid deep type checking
interface MinimalAAProvider {
  account: {
    address: `0x${string}`;
  };
  sendUserOperation: (params: {
    account: { address: `0x${string}` };
    calls: Array<{
      to: `0x${string}`;
      data: `0x${string}`;
      value: bigint;
    }>;
  }) => Promise<string | { hash?: string }>;
  waitForUserOperationReceipt: (params: { hash: `0x${string}` }) => Promise<{
    receipt: { transactionHash: `0x${string}` };
  }>;
}

export const useDPPMinting = () => {
  const { primaryWallet } = useDynamicContext();
  const { toast } = useToast();
  const [contractAddresses, setContractAddresses] = useState<{
    complianceAddress?: `0x${string}`;
    lifecycleAddress?: `0x${string}`;
  }>({});
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(true);
  
  const [txState, setTxState] = useState<TransactionState>({
    isMinting: false,
    hasMinted: false,
    error: null,
    transactionHash: null,
  });

  // Fetch contract addresses on mount
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        setIsLoadingAddresses(true);
        const addresses = await getContractAddresses();
        setContractAddresses({
          complianceAddress: addresses.public.compliance as `0x${string}`,
          lifecycleAddress: addresses.public.lifecycle as `0x${string}`,
        });
      } catch (error) {
        console.error("Failed to fetch contract addresses:", error);
        toast({
          title: "Error",
          description: "Failed to load contract addresses. Please refresh and try again.",
          variant: "destructive",
        });
      } finally {
        setIsLoadingAddresses(false);
      }
    };
    
    fetchAddresses();
  }, [toast]);

  const mintDPP = async (metadata: DPPMetadata) => {
    const connector = await primaryWallet?.connector;
    
    if (!connector || !isZeroDevConnector(connector)) {
      toast({
        title: "Error",
        description: "Account Abstraction wallet not available",
        variant: "destructive",
      });
      return;
    }

    if (isLoadingAddresses || !contractAddresses.complianceAddress) {
      toast({
        title: "Error", 
        description: "Contract addresses not loaded yet. Please wait and try again.",
        variant: "destructive",
      });
      return;
    }

    try {
      setTxState({ ...txState, isMinting: true });
      
      // Get the AA provider from the connector
      const rawProvider = connector.getAccountAbstractionProvider();
      if (!rawProvider) throw new Error("AA provider not available");
      
      // Cast to our minimal interface to avoid TypeScript recursion
      const aaProvider = rawProvider as unknown as MinimalAAProvider;

      // Generate DID for the manufacturer
      const walletAddress = primaryWallet?.address;
      if (!walletAddress) throw new Error("Wallet address not available");
      
      const manufacturerDid = await generateDid(walletAddress);
      
      // Upload data to IPFS with encryption for private data
      const { publicUri, privateUri, encryptionKey } = await uploadToIPFS(
        metadata,
        manufacturerDid,
        metadata.basicInfo.manufacturerName
      );

      // Create audit log
      const auditLog = JSON.stringify({
        creationTime: Date.now(),
        creator: walletAddress,
        action: 'MINT',
        metadata: {
          publicUri,
          encryptedPrivateUri: privateUri,
          encryptionKeyHash: encryptionKey ? btoa(encryptionKey) : undefined
        }
      });

      // Create the call data for the contract
      const callData = encodeFunctionData({
        abi: DPPContractABI,
        functionName: "mintDPP",
        args: [
          walletAddress as `0x${string}`,
          publicUri,
          privateUri,
          metadata.basicInfo.productId,
          metadata.basicInfo.idScheme,
          metadata.basicInfo.productName,
          metadata.basicInfo.manufacturerName,
          metadata.basicInfo.category,
          BigInt(new Date(metadata.manufacturing.productionDate).getTime() / 1000),
          metadata.manufacturing.country,
          BigInt(metadata.sustainability.carbonFootprint * 100),
          BigInt(metadata.sustainability.repairabilityIndex * 100),
          metadata.materials.hasHazardousMaterials,
          metadata.certifications.list,
          metadata.compliance.standards,
        ],
      });

      // Prepare the call object with our simplified structure
      const callObj = {
        to: contractAddresses.complianceAddress,
        data: callData as `0x${string}`,
        value: 0n
      };
      
      // Call sendUserOperation with our simplified interface
      const txResponse = await aaProvider.sendUserOperation({
        account: aaProvider.account,
        calls: [callObj]
      });
      
      // Handle different response formats to extract hash
      let txHash: `0x${string}`;
      
      if (typeof txResponse === 'string') {
        txHash = txResponse.startsWith('0x') ? txResponse as `0x${string}` : `0x${txResponse}`;
      } else if (txResponse && typeof txResponse === 'object') {
        // For ZeroDev SDK v5, the hash might be in a different location
        if ('hash' in txResponse) {
          const hashValue = (txResponse as any).hash;
          txHash = typeof hashValue === 'string' && hashValue.startsWith('0x') 
            ? hashValue as `0x${string}` 
            : `0x${hashValue}`;
        } else {
          // If the structure is different, try to find the hash in the response
          const possibleHash = Object.values(txResponse).find(val => 
            typeof val === 'string' && (val.startsWith('0x') || /^[0-9a-fA-F]+$/.test(val))
          ) as string;
          
          if (!possibleHash) throw new Error("Could not find transaction hash in response");
          
          txHash = possibleHash.startsWith('0x') 
            ? possibleHash as `0x${string}` 
            : `0x${possibleHash}`;
        }
      } else {
        throw new Error("Invalid transaction response format");
      }
      
      // Wait for the transaction receipt with proper hash formatting
      const receipt = await aaProvider.waitForUserOperationReceipt({
        hash: txHash
      });

      setTxState({
        isMinting: false,
        hasMinted: true,
        error: null,
        transactionHash: receipt.receipt.transactionHash,
      });

      toast({
        title: "Success",
        description: "DPP minted successfully!",
      });

      return receipt.receipt.transactionHash;
    } catch (error) {
      console.error("Minting failed:", error);
      setTxState({
        ...txState,
        isMinting: false,
        error: error as Error,
      });
      
      toast({
        title: "Error",
        description: "Failed to mint DPP. Please try again.",
        variant: "destructive",
      });
      
      throw error;
    }
  };

  const batchMintDPPs = async (metadataArray: DPPMetadata[]) => {
    toast({
      title: "Not Implemented",
      description: "Batch minting is not yet implemented.",
      variant: "destructive",
    });
    return null;
  };

  return {
    txState,
    mintDPP,
    batchMintDPPs,
    isLoadingAddresses,
    contractAddresses
  };
};
