
// Using Web Crypto API for browser compatibility instead of Node.js crypto
export interface EncryptedData {
  data: string;
  iv: string;
  key: string;
}

export const encryptData = async (data: string): Promise<EncryptedData> => {
  // Generate a random 256-bit key and IV
  const key = crypto.getRandomValues(new Uint8Array(32));
  const iv = crypto.getRandomValues(new Uint8Array(16));

  // Import the key
  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    key,
    { name: 'AES-GCM' },
    false,
    ['encrypt']
  );

  // Convert data to buffer
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data);

  // Encrypt the data
  const encryptedBuffer = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv
    },
    cryptoKey,
    dataBuffer
  );

  // Convert to base64
  const encryptedData = btoa(String.fromCharCode(...new Uint8Array(encryptedBuffer)));
  const ivString = btoa(String.fromCharCode(...iv));
  const keyString = btoa(String.fromCharCode(...key));

  return {
    data: encryptedData,
    iv: ivString,
    key: keyString
  };
};

export const decryptData = async (
  encryptedData: EncryptedData
): Promise<string> => {
  // Convert from base64
  const encryptedBuffer = Uint8Array.from(
    atob(encryptedData.data).split('').map(c => c.charCodeAt(0))
  );
  const iv = Uint8Array.from(
    atob(encryptedData.iv).split('').map(c => c.charCodeAt(0))
  );
  const key = Uint8Array.from(
    atob(encryptedData.key).split('').map(c => c.charCodeAt(0))
  );

  // Import the key
  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    key,
    { name: 'AES-GCM' },
    false,
    ['decrypt']
  );

  // Decrypt the data
  const decryptedBuffer = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv
    },
    cryptoKey,
    encryptedBuffer
  );

  // Convert buffer back to string
  const decoder = new TextDecoder();
  return decoder.decode(decryptedBuffer);
};

export const encryptForIPFS = async (
  data: any,
  publicKey: string
): Promise<{ encryptedData: string; encryptionKey: string }> => {
  // Convert data to string if it's an object
  const dataString = typeof data === 'string' ? data : JSON.stringify(data);

  // Encrypt the data
  const encrypted = await encryptData(dataString);

  // The encryption key will be shared off-chain with authorized parties
  return {
    encryptedData: JSON.stringify({
      data: encrypted.data,
      iv: encrypted.iv
    }),
    encryptionKey: encrypted.key
  };
};

export const decryptFromIPFS = async (
  encryptedIPFSData: string,
  encryptionKey: string
): Promise<any> => {
  const { data, iv } = JSON.parse(encryptedIPFSData);

  const decrypted = await decryptData({
    data,
    iv,
    key: encryptionKey
  });

  try {
    // Try to parse as JSON if possible
    return JSON.parse(decrypted);
  } catch {
    // Return as is if not JSON
    return decrypted;
  }
};
