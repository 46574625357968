import { supabase } from "@/integrations/supabase/client";
import { DPPFormData } from "@/types/dpp-form";
import { toast } from "sonner";

// Validates that the form data has essential fields
export const validateFormData = (formData: DPPFormData): boolean => {
  // Check if productBasics exists and has a name
  if (!formData.productBasics || !formData.productBasics.productName) {
    return false;
  }
  
  // More validation can be added here as needed
  return true;
};

// Saves DPP draft to Supabase dpp_drafts table
export const saveDppDraft = async (
  formData: DPPFormData,
  userId: string,
  draftId?: string, 
  activeTab?: string
): Promise<{ success: boolean; draftId?: string; error?: any }> => {
  try {
    const isValid = validateFormData(formData);
    if (!isValid) {
      console.warn("Form data validation failed, saving as incomplete draft");
    }

    // Extract product category from formData
    const productCategory = Array.isArray(formData.productBasics?.productCategory)
      ? formData.productBasics.productCategory[0]?.name || "uncategorized"
      : "uncategorized";

    // Calculate last step based on active tab
    let lastStep = 1;
    if (activeTab) {
      switch (activeTab) {
        case "review": lastStep = 7; break;
        case "lifecycle": lastStep = 6; break;
        case "compliance": lastStep = 5; break;
        case "sustainability": lastStep = 4; break;
        case "materials": lastStep = 3; break;
        case "manufacturing": lastStep = 2; break;
        default: lastStep = 1;
      }
    }

    // Prepare the draft data with all fields from our new schema
    const draftData = {
      user_id: userId,
      profile_id: userId, // For legacy support
      name: formData.productBasics?.productName || "Untitled DPP",
      product_name: formData.productBasics?.productName || "Untitled DPP",
      description: formData.productBasics?.productDescription || "",
      product_category: productCategory,
      category: productCategory,
      product_image: formData.productBasics?.productImage || "",
      image_url: formData.productBasics?.productImage || "",
      sku: formData.productBasics?.serialNumber || "",
      gtin: formData.productBasics?.productId || "",
      production_date: formData.productBasics?.productionDate 
        ? new Date(formData.productBasics.productionDate).toISOString() 
        : new Date().toISOString(),
      country_of_production: formData.productBasics?.manufacturingCountry || "",
      status: "draft",
      last_step: lastStep,
      last_auto_save: new Date().toISOString(),
      manufacturer_name: formData.manufacturingDetails?.manufacturerName || "",
      facility_name: formData.manufacturingDetails?.facilityName || "",
      material_count: formData.materials?.length || 0,
      sustainability_score: formData.sustainability?.circularityScorecard?.materialCircularityIndicator || 0,
      compliance_certifications: formData.compliance?.certifications?.length || 0,
      // Store the complete form data as JSON
      form_data: formData,
      // Additional metadata that may be useful for filtering/searching
      optional_public_metadata: {
        manufacturerName: formData.manufacturingDetails?.manufacturerName,
        facilityName: formData.manufacturingDetails?.facilityName,
        materialCount: formData.materials?.length || 0,
        sustainabilityScore: formData.sustainability?.circularityScorecard?.materialCircularityIndicator,
        complianceCertifications: formData.compliance?.certifications?.length || 0,
      }
    };

    // If draftId exists, update the existing draft
    if (draftId) {
      const { error } = await supabase
        .from("dpp_drafts")
        .update(draftData)
        .eq("id", draftId)
        .eq("user_id", userId);

      if (error) throw error;
      return { success: true, draftId };
    } 
    // Otherwise create a new draft
    else {
      const { data, error } = await supabase
        .from("dpp_drafts")
        .insert(draftData)
        .select()
        .single();

      if (error) throw error;
      
      return { 
        success: true, 
        draftId: data?.id 
      };
    }
  } catch (error) {
    console.error("Error saving DPP draft:", error);
    return { 
      success: false, 
      error 
    };
  }
};

// Loads a DPP draft from Supabase
export const loadDppDraft = async (
  draftId: string, 
  userId: string
): Promise<{ success: boolean; formData?: DPPFormData; error?: any }> => {
  try {
    const { data, error } = await supabase
      .from("dpp_drafts")
      .select("*")
      .eq("id", draftId)
      .eq("user_id", userId)
      .single();

    if (error) throw error;
    
    if (data && data.form_data) {
      return {
        success: true,
        formData: data.form_data as unknown as DPPFormData
      };
    }
    
    throw new Error("No form data found in the draft");
  } catch (error) {
    console.error("Error loading DPP draft:", error);
    return {
      success: false,
      error
    };
  }
};
