
import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { supabase } from '@/integrations/supabase/client';
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";

interface MapPickerProps {
  onLocationSelect: (latitude: string, longitude: string) => void;
  initialLatitude?: string;
  initialLongitude?: string;
}

export function MapPicker({ onLocationSelect, initialLatitude, initialLongitude }: MapPickerProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    // Small delay to ensure DOM is fully rendered
    const initTimer = setTimeout(() => {
      initializeMap();
    }, 500); // Increased delay to ensure DOM is fully rendered
    
    return () => {
      clearTimeout(initTimer);
      // Clean up map on unmount
      try {
        if (map.current) {
          console.log('Cleaning up map...');
          // Remove markers first
          if (marker.current) {
            marker.current.remove();
            marker.current = null;
          }
          // Safely remove the map
          map.current.remove();
          map.current = null;
        }
      } catch (error) {
        console.error('Error cleaning up map:', error);
        // Reset references even if cleanup fails
        marker.current = null;
        map.current = null;
      }
    };
  }, [initialLatitude, initialLongitude]);
  
  const initializeMap = async () => {
    console.log('Starting map initialization...');
    try {
      if (!mapContainer.current) {
        console.error('Map container not found');
        return;
      }

      // Get Mapbox token from environment variables
      const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
      
      if (mapboxToken) {
        // Use the token from the environment file
        console.log('Using Mapbox token from environment variables');
        mapboxgl.accessToken = mapboxToken;
      } else {
        // Fallback to demo token if no token is provided
        console.warn('No Mapbox token found in environment variables. Using demo token with limited functionality.');
        try {
          // Use public Mapbox token for testing only
          mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';
          
          // Show a toast warning about the missing token
          // Using setTimeout to avoid toast rendering issues during component initialization
          setTimeout(() => {
            toast({
              title: "Using Mapbox demo token",
              description: "For full functionality, please add your Mapbox token to your environment variables.",
              duration: 5000
            });
          }, 1000);
        } catch (tokenError) {
          console.error('Error setting Mapbox token:', tokenError);
          setError('Failed to initialize map. Please check your internet connection and try again.');
          setIsLoading(false);
          return;
        }
      }

        console.log('Initializing map with container:', mapContainer.current);
        console.log('Container dimensions:', {
          width: mapContainer.current.offsetWidth,
          height: mapContainer.current.offsetHeight
        });

        const initialCoordinates: [number, number] = [
          initialLongitude ? parseFloat(initialLongitude) : -74.5,
          initialLatitude ? parseFloat(initialLatitude) : 40
        ];

        console.log('Setting up map with coordinates:', initialCoordinates);

        try {
          map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: initialCoordinates,
            zoom: 2,
            transformRequest: (url, resourceType) => {
              // Log all resource requests to help debug ERR_NAME_NOT_RESOLVED issues
              console.log(`Mapbox resource request: ${resourceType} - ${url}`);
              return { url };
            }
          });
        } catch (mapInitError) {
          console.error('Failed to initialize Mapbox map:', mapInitError);
          setError('Failed to initialize map. Please check your internet connection and ensure Mapbox resources are accessible.');
          setIsLoading(false);
          return;
        }

        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

        map.current.on('error', (e) => {
          console.error('Mapbox error:', e);
          setError('Map error: ' + e.error.message);
          // Delay toast to prevent render conflicts
          setTimeout(() => {
            toast({
              variant: "destructive",
              title: "Map Error",
              description: e.error.message
            });
          }, 100);
        });

        marker.current = new mapboxgl.Marker({
          draggable: true
        })
          .setLngLat(initialCoordinates)
          .addTo(map.current);

        marker.current.on('dragend', (e) => {
          // Prevent default browser behavior
          if (e && e.originalEvent) {
            e.originalEvent.stopPropagation();
            e.originalEvent.preventDefault();
          }
          
          if (marker.current) {
            const { lng, lat } = marker.current.getLngLat();
            console.log('Marker dragged to:', { lng, lat });
            // Use setTimeout to ensure this doesn't interfere with form events
            setTimeout(() => {
              onLocationSelect(lat.toFixed(6), lng.toFixed(6));
            }, 0);
          }
        });

        map.current.on('click', (e) => {
          console.log('Map clicked at:', e.lngLat);
          // Prevent event propagation to avoid triggering form submissions
          e.originalEvent.stopPropagation();
          e.originalEvent.preventDefault();
          
          if (marker.current) {
            marker.current.setLngLat(e.lngLat);
            // Safely update the location without triggering form submissions
            setTimeout(() => {
              onLocationSelect(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));
            }, 0);
          }
        });

        map.current.on('load', () => {
          console.log('Map loaded successfully');
          setIsLoading(false);
        });

      } catch (err) {
        console.error('Error initializing map:', err);
        setError(err instanceof Error ? err.message : 'Failed to initialize map');
        setIsLoading(false);
        toast({
          variant: "destructive",
          title: "Error loading map",
          description: "Failed to initialize the map. Please try again later."
        });
      }
    };

    // Console log moved to the beginning of initializeMap function

  if (error) {
    return (
      <div className="h-[300px] w-full rounded-lg border bg-background p-4 flex items-center justify-center text-destructive">
        <p>Error loading map: {error}</p>
      </div>
    );
  }

  // Prevent form submission when interacting with the map container
  const handleMapInteraction = (e: React.MouseEvent | React.TouchEvent) => {
    // This prevents the map interactions from bubbling up to any parent forms
    e.stopPropagation();
  };

  return (
    <div 
      className="w-full rounded-lg relative"
      onClick={handleMapInteraction}
      onMouseDown={handleMapInteraction}
      onTouchStart={handleMapInteraction}
    >
      {isLoading && (
        <div className="absolute inset-0 z-10 bg-background/80 flex items-center justify-center">
          <Skeleton className="h-[300px] w-full rounded-lg" />
        </div>
      )}
      <div 
        ref={mapContainer} 
        className="h-[300px] w-full rounded-lg" 
        style={{ visibility: isLoading ? 'hidden' : 'visible' }}
      />
    </div>
  );
}
