import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { ComplianceFormData, SecureLink } from "@/types/dpp-form";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";

interface ComplianceFormProps {
  onDataChange: (data: Partial<ComplianceFormData>) => void;
  initialData?: Partial<ComplianceFormData>;
}

export function ComplianceForm({ onDataChange, initialData }: ComplianceFormProps) {
  const [formData, setFormData] = useState<Partial<ComplianceFormData>>(initialData || {
    dueDiligenceDeclaration: {
      linkURL: '',
      linkName: '',
      linkType: 'document',
      hashDigest: '',
      hashMethod: 'SHA-256',
      encryptionMethod: 'none'
    },
    conformityClaims: [],
    certifications: []
  });

  const [newClaim, setNewClaim] = useState({
    claim: '',
    evidence: {
      linkURL: '',
      linkName: '',
      linkType: 'document',
      hashDigest: '',
      hashMethod: 'SHA-256',
      encryptionMethod: 'none'
    },
    testReports: []
  });

  const [newCertification, setNewCertification] = useState("");
  const [newTestReport, setNewTestReport] = useState<SecureLink>({
    linkURL: '',
    linkName: '',
    linkType: 'document',
    hashDigest: '',
    hashMethod: 'SHA-256',
    encryptionMethod: 'none'
  });

  const handleChange = (field: keyof ComplianceFormData, value: any) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    onDataChange(updatedData);
  };

  const addConformityClaim = () => {
    if (newClaim.claim.trim() && newClaim.evidence.linkURL.trim()) {
      const currentClaims = formData.conformityClaims || [];
      handleChange('conformityClaims', [...currentClaims, { ...newClaim }]);
      setNewClaim({
        claim: '',
        evidence: {
          linkURL: '',
          linkName: '',
          linkType: 'document',
          hashDigest: '',
          hashMethod: 'SHA-256',
          encryptionMethod: 'none'
        },
        testReports: []
      });
    }
  };

  const addTestReport = (claimIndex: number) => {
    if (newTestReport.linkURL.trim() && newTestReport.linkName.trim()) {
      const currentClaims = [...(formData.conformityClaims || [])];
      currentClaims[claimIndex].testReports.push({ ...newTestReport });
      handleChange('conformityClaims', currentClaims);
      setNewTestReport({
        linkURL: '',
        linkName: '',
        linkType: 'document',
        hashDigest: '',
        hashMethod: 'SHA-256',
        encryptionMethod: 'none'
      });
    }
  };

  const addCertification = () => {
    if (newCertification.trim()) {
      const currentCertifications = formData.certifications || [];
      handleChange('certifications', [...currentCertifications, newCertification.trim()]);
      setNewCertification("");
    }
  };

  return (
    <div className="space-y-8">
      {/* Due Diligence Declaration */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Due Diligence Declaration</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label>Document Name</Label>
            <Input
              value={formData.dueDiligenceDeclaration?.linkName || ''}
              onChange={(e) => handleChange('dueDiligenceDeclaration', {
                ...formData.dueDiligenceDeclaration as SecureLink,
                linkName: e.target.value
              })}
              placeholder="Enter document name"
            />
          </div>
          <div className="space-y-2">
            <Label>Document URL</Label>
            <Input
              value={formData.dueDiligenceDeclaration?.linkURL || ''}
              onChange={(e) => handleChange('dueDiligenceDeclaration', {
                ...formData.dueDiligenceDeclaration as SecureLink,
                linkURL: e.target.value
              })}
              placeholder="Enter document URL"
            />
          </div>
        </div>
      </div>

      {/* Conformity Claims */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Conformity Claims</h3>
        <div className="space-y-4">
          {/* Add new claim form */}
          <div className="space-y-2 border-b pb-4">
            <Label>New Claim</Label>
            <Textarea
              value={newClaim.claim}
              onChange={(e) => setNewClaim({ ...newClaim, claim: e.target.value })}
              placeholder="Enter conformity claim"
            />
            <Label>Evidence Link</Label>
            <div className="grid grid-cols-2 gap-4">
              <Input
                value={newClaim.evidence.linkName}
                onChange={(e) => setNewClaim({
                  ...newClaim,
                  evidence: { ...newClaim.evidence, linkName: e.target.value }
                })}
                placeholder="Evidence name"
              />
              <Input
                value={newClaim.evidence.linkURL}
                onChange={(e) => setNewClaim({
                  ...newClaim,
                  evidence: { ...newClaim.evidence, linkURL: e.target.value }
                })}
                placeholder="Evidence URL"
              />
            </div>
            <Button 
              onClick={addConformityClaim}
              className="w-full"
              disabled={!newClaim.claim.trim() || !newClaim.evidence.linkURL.trim() || !newClaim.evidence.linkName.trim()}
            >
              Add Claim
            </Button>
          </div>

          {/* Existing claims */}
          <div className="space-y-4">
            {formData.conformityClaims?.map((claim, claimIndex) => (
              <div key={claimIndex} className="border rounded-lg p-4 space-y-2">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="font-medium">{claim.claim}</p>
                    <p className="text-sm text-muted-foreground">
                      Evidence: {claim.evidence.linkName || claim.evidence.linkURL}
                    </p>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      const newClaims = formData.conformityClaims?.filter((_, i) => i !== claimIndex);
                      handleChange('conformityClaims', newClaims);
                    }}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>

                {/* Test Reports */}
                <div className="space-y-2">
                  <Label>Test Reports</Label>
                  <div className="grid grid-cols-2 gap-4">
                    <Input
                      value={newTestReport.linkName}
                      onChange={(e) => setNewTestReport({
                        ...newTestReport,
                        linkName: e.target.value
                      })}
                      placeholder="Report name"
                    />
                    <Input
                      value={newTestReport.linkURL}
                      onChange={(e) => setNewTestReport({
                        ...newTestReport,
                        linkURL: e.target.value
                      })}
                      placeholder="Report URL"
                    />
                  </div>
                  <Button 
                    onClick={() => addTestReport(claimIndex)}
                    className="w-full"
                    disabled={!newTestReport.linkName.trim() || !newTestReport.linkURL.trim()}
                  >
                    Add Report
                  </Button>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {claim.testReports.map((report, reportIndex) => (
                      <div key={reportIndex} className="bg-muted px-2 py-1 rounded-md flex items-center">
                        {report.linkName}
                        <Button
                          variant="ghost"
                          size="sm"
                          className="ml-2"
                          onClick={() => {
                            const newClaims = [...(formData.conformityClaims || [])];
                            newClaims[claimIndex].testReports = 
                              newClaims[claimIndex].testReports.filter((_, i) => i !== reportIndex);
                            handleChange('conformityClaims', newClaims);
                          }}
                        >
                          <X className="h-3 w-3" />
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Certifications */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Certifications</h3>
        <div className="space-y-2">
          <Label>Add Certification</Label>
          <div className="grid grid-cols-2 gap-4">
            <Input
              value={newCertification}
              onChange={(e) => setNewCertification(e.target.value)}
              placeholder="Certification name"
            />
            <Button 
              onClick={addCertification}
              className="w-full"
              disabled={!newCertification.trim()}
            >
              Add Certification
            </Button>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {formData.certifications?.map((cert, index) => (
              <div key={index} className="bg-muted px-2 py-1 rounded-md flex items-center">
                {cert}
                <Button
                  variant="ghost"
                  size="sm"
                  className="ml-2"
                  onClick={() => {
                    const newCerts = formData.certifications?.filter((_, i) => i !== index);
                    handleChange('certifications', newCerts);
                  }}
                >
                  <X className="h-3 w-3" />
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComplianceForm;
