
export interface ContractAddresses {
  public: {
    compliance: string;
    lifecycle: string;
  };
  private: {
    compliance: string;
    lifecycle: string;
  };
}

/**
 * Gets contract addresses directly from environment variables
 * This is more reliable than calling a Supabase function
 */
export const getContractAddresses = async (): Promise<ContractAddresses> => {
  try {
    // Get addresses from environment variables
    const publicComplianceAddress = import.meta.env.VITE_DPPX_PUBLIC_COMPLIANCE_ADDRESS;
    const publicLifecycleAddress = import.meta.env.VITE_DPPX_PUBLIC_LIFECYCLE_ADDRESS;
    const privateComplianceAddress = import.meta.env.VITE_DPPX_PRIVATE_COMPLIANCE_ADDRESS;
    const privateLifecycleAddress = import.meta.env.VITE_DPPX_PRIVATE_LIFECYCLE_ADDRESS;
    
    // Validate addresses
    if (!publicComplianceAddress || !publicLifecycleAddress || 
        !privateComplianceAddress || !privateLifecycleAddress) {
      throw new Error("Missing contract addresses in environment variables");
    }
    
    return {
      public: {
        compliance: publicComplianceAddress,
        lifecycle: publicLifecycleAddress
      },
      private: {
        compliance: privateComplianceAddress,
        lifecycle: privateLifecycleAddress
      }
    };
  } catch (error) {
    console.error("Error in getContractAddresses:", error);
    throw error;
  }
};
