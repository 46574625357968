
/**
@author zspotter
        (IG @zzz_desu, TW @zspotter)
@title  Slime Dish
@desc   Low-res physarum slime mold simulation

With inspiration from:
- https://sagejenson.com/physarum
- https://uwe-repository.worktribe.com/output/980579
- http://www.tech-algorithm.com/articles/nearest-neighbor-image-scaling
*/

import { useEffect, useRef, useState } from 'react';
import * as v2 from '../modules/vec2.js';
import { map } from '../modules/num.js';

// Environment
const WIDTH = 400;
const HEIGHT = 400;
const NUM_AGENTS = 1500;
const DECAY = 0.95;
const MIN_CHEM = 0.0001;

// Agents
const SENS_ANGLE = 45 * Math.PI / 180;
const SENS_DIST = 9;
const AGT_SPEED = 0.5;
const AGT_ANGLE = 45 * Math.PI / 180;
const DEPOSIT = 0.5;

// Animation
const FRAME_INTERVAL = 16; // Reduced from 25 for faster updates

// Rendering
const TEXTURE = [
  "  ``^@",
  " ..„v0",
];
const OOB = ' ';

class Agent {
  pos: any;
  dir: any;
  scatter: boolean;

  constructor(pos: any, dir: any) {
    this.pos = pos;
    this.dir = dir;
    this.scatter = false;
  }

  sense(m: number, chem: Float32Array) {
    const senseVec = v2.mulN(v2.rot(this.dir, m * SENS_ANGLE), SENS_DIST);
    const pos = v2.floor(v2.add(this.pos, senseVec));
    if (!bounded(pos))
      return -1;
    const sensed = chem[pos.y*HEIGHT+pos.x];
    if (this.scatter)
      return 1 - sensed;
    return sensed;
  }

  react(chem: Float32Array) {
    // Sense
    let forwardChem = this.sense(0, chem);
    let leftChem = this.sense(-1, chem);
    let rightChem = this.sense(1, chem);

    // Rotate
    let rotate = 0;
    if (forwardChem > leftChem && forwardChem > rightChem) {
      rotate = 0;
    }
    else if (forwardChem < leftChem && forwardChem < rightChem) {
      if (Math.random() < 0.5) {
        rotate = -AGT_ANGLE;
      }
      else {
        rotate = AGT_ANGLE;
      }
    }
    else if (leftChem < rightChem) {
      rotate = AGT_ANGLE;
    }
    else if (rightChem < leftChem) {
      rotate = -AGT_ANGLE;
    }
    else if (forwardChem < 0) {
      // Turn around at edge
      rotate = Math.PI/2;
    }
    this.dir = v2.rot(this.dir, rotate);

    // Move
    this.pos = v2.add(this.pos, v2.mulN(this.dir, AGT_SPEED));
  }

  deposit(chem: Float32Array) {
    const {y, x} = v2.floor(this.pos);
    const i = y*HEIGHT+x;
    chem[i] = Math.min(1, chem[i] + DEPOSIT);
  }
}

const R = Math.min(WIDTH, HEIGHT)/2;

function bounded(vec: any) {
  return ((vec.x-R)**2 + (vec.y-R)**2 <= R**2);
}

function blur(row: number, col: number, data: Float32Array) {
  let sum = 0;
  for (let dy = -1; dy <= 1; dy++) {
    for (let dx = -1; dx <= 1; dx++) {
      sum += data[(row+dy)*HEIGHT + col + dx] ?? 0;
    }
  }
  return sum / 9;
}

function randCircle() {
  const r = Math.sqrt(Math.random());
  const theta = Math.random() * 2 * Math.PI;
  return {
    x: r * Math.cos(theta),
    y: r * Math.sin(theta)
  };
}

function getAsciiOutput(data: {
  chem: Float32Array;
}, context: { rows: number; cols: number; }) {
  let output = '';
  for (let row = 0; row < context.rows; row++) {
    for (let col = 0; col < context.cols; col++) {
      const coord = { x: col, y: row };

      const sampleFrom = {
        y: Math.floor(coord.y * HEIGHT / context.rows),
        x: Math.floor(coord.x * WIDTH / context.cols),
      };

      const sampleTo = {
        y: Math.floor((coord.y + 1) * HEIGHT / context.rows),
        x: Math.floor((coord.x + 1) * WIDTH / context.cols),
      };

      if (!bounded(sampleFrom) || !bounded(sampleTo)) {
        output += OOB;
        continue;
      }

      const sampleH = Math.max(1, sampleTo.y - sampleFrom.y);
      const sampleW = Math.max(1, sampleTo.x - sampleFrom.x);

      let max = 0;
      let sum = 0;
      for (let x = sampleFrom.x; x < sampleFrom.x + sampleW; x++) {
        for (let y = sampleFrom.y; y < sampleFrom.y + sampleH; y++) {
          const v = data.chem[y*HEIGHT+x];
          max = Math.max(max, v);
          sum += v;
        }
      }
      let val = sum / (sampleW * sampleH);
      val = (val + max) / 2;
      val = Math.pow(val, 1/3);

      const texRow = (coord.x+coord.y) % TEXTURE.length;
      const texCol = Math.ceil(val * (TEXTURE[0].length-1));
      output += TEXTURE[texRow][texCol] || OOB;
    }
    output += '\n';
  }
  return output;
}

const Slime = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const frameIdRef = useRef<number>();
  const lastFrameTimeRef = useRef<number>(0);
  const dataRef = useRef<{
    chem: Float32Array;
    wip: Float32Array;
    agents: Agent[];
  }>();
  const [output, setOutput] = useState('');

  useEffect(() => {
    // Initialize data
    dataRef.current = {
      chem: new Float32Array(HEIGHT*WIDTH),
      wip: new Float32Array(HEIGHT*WIDTH),
      agents: [],
    };

    // Create agents
    for (let i = 0; i < NUM_AGENTS; i++) {
      dataRef.current.agents.push(new Agent(
        v2.mulN(v2.addN(v2.mulN(randCircle(), 0.5), 1), 0.5 * WIDTH),
        v2.rot(v2.vec2(1, 0), Math.random()*2*Math.PI)
      ));
    }

    const animate = (timestamp: number) => {
      if (!dataRef.current || !containerRef.current) return;

      // Control frame rate
      if (timestamp - lastFrameTimeRef.current < FRAME_INTERVAL) {
        frameIdRef.current = requestAnimationFrame(animate);
        return;
      }
      lastFrameTimeRef.current = timestamp;

      const data = dataRef.current;

      // Diffuse & decay
      for (let row = 0; row < HEIGHT; row++) {
        for (let col = 0; col < WIDTH; col++) {
          let val = DECAY * blur(row, col, data.chem);
          if (val < MIN_CHEM)
            val = 0;
          data.wip[row*HEIGHT+col] = val;
        }
      }
      const swap = data.chem;
      data.chem = data.wip;
      data.wip = swap;

      // Update agents
      const frame = Math.floor(timestamp / 16); // Reduced from 25 for faster animation
      const isScattering = Math.sin(frame/100) > 0.8; // Reduced from 150 for faster pattern changes
      for (const agent of data.agents) {
        agent.scatter = isScattering;
        agent.react(data.chem);
        agent.deposit(data.chem);
      }

      // Render
      const context = {
        rows: Math.floor(containerRef.current.clientHeight / 15),
        cols: Math.floor(containerRef.current.clientWidth / 8),
      };

      setOutput(getAsciiOutput(data, context));

      frameIdRef.current = requestAnimationFrame(animate);
    };

    frameIdRef.current = requestAnimationFrame(animate);

    return () => {
      if (frameIdRef.current) {
        cancelAnimationFrame(frameIdRef.current);
      }
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black w-screen h-screen">
      {/* Vignette overlay */}
      <div className="absolute inset-0 pointer-events-none" style={{
        background: 'radial-gradient(circle, transparent 30%, rgba(0,0,0,0.8) 100%)'
      }} />
      
      <div ref={containerRef} className="w-[90%] h-[90%] flex items-center justify-center relative">
        <pre 
          className="text-white font-mono whitespace-pre select-none"
          style={{ 
            fontSize: '14px',
            lineHeight: '14px',
            letterSpacing: '1px', // Reduced from 2px for closer letters
            textAlign: 'center'
          }}
        >
          {output}
        </pre>
      </div>
    </div>
  );
};

export default Slime;
