import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import {
  MaterialData,
  Measure,
  SecureLink,
  MaterialProvenanceFormData,
  Classification
} from "@/types/dpp-form";

interface MaterialProvenanceFormProps {
  onDataChange: (data: MaterialProvenanceFormData) => void;
  initialData?: MaterialProvenanceFormData;
}

export function MaterialProvenanceForm({ onDataChange, initialData }: MaterialProvenanceFormProps) {
  const [formData, setFormData] = useState<MaterialProvenanceFormData>(initialData || {
    materials: [],
    totalRecycledContent: 0,
    totalBiobasedContent: 0,
    msdsLink: {
      linkURL: '',
      linkName: '',
      linkType: '',
      hashDigest: '',
      hashMethod: 'SHA-256',
      encryptionMethod: 'none'
    },
    dueDiligenceDeclaration: {
      linkURL: '',
      linkName: '',
      linkType: '',
      hashDigest: '',
      hashMethod: 'SHA-256',
      encryptionMethod: 'none'
    }
  });

  const [newMaterial, setNewMaterial] = useState<MaterialData>({
    materialName: '',
    materialType: { id: '', code: '', name: '', schemeID: '', schemeName: '' },
    massAmount: { value: 0, unit: '' },
    massFraction: 0,
    recycledContent: 0,
    biobasedContent: 0,
    materialCertifications: [],
    msdsLink: {
      linkURL: '',
      linkName: '',
      linkType: 'document',
      hashDigest: '',
      hashMethod: 'SHA-256',
      encryptionMethod: 'none'
    },
    originCountry: '',
    restrictedSubstances: [],
    hazardous: false,
    recycledAmount: 0
  });

  const [newCertification, setNewCertification] = useState("");
  const [newRestrictedSubstance, setNewRestrictedSubstance] = useState("");

  const handleChange = (field: keyof MaterialProvenanceFormData, value: any) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    onDataChange(updatedData);
  };

  const handleMaterialChange = (index: number, field: keyof MaterialData, value: any) => {
    const updatedMaterials = [...formData.materials];
    updatedMaterials[index] = { ...updatedMaterials[index], [field]: value };
    handleChange('materials', updatedMaterials);
  };

  const addMaterial = () => {
    const newMaterial: MaterialData = {
      materialName: '',
      materialType: { id: '', code: '', name: '', schemeID: '', schemeName: '' },
      massAmount: { value: 0, unit: '' },
      massFraction: 0,
      recycledContent: 0,
      biobasedContent: 0,
      materialCertifications: [],
      msdsLink: {
        linkURL: '',
        linkName: '',
        linkType: 'document',
        hashDigest: '',
        hashMethod: 'SHA-256',
        encryptionMethod: 'none'
      },
      originCountry: '',
      restrictedSubstances: [],
      hazardous: false,
      recycledAmount: 0
    };

    handleChange('materials', [...formData.materials, newMaterial]);
  };

  const removeMaterial = (index: number) => {
    const updatedMaterials = formData.materials.filter((_, i) => i !== index);
    handleChange('materials', updatedMaterials);
  };

  const addCertification = (index: number) => {
    if (newCertification.trim()) {
      const updatedMaterials = [...formData.materials];
      const currentCertifications = updatedMaterials[index].materialCertifications || [];
      updatedMaterials[index].materialCertifications = [...currentCertifications, newCertification.trim()];
      handleChange('materials', updatedMaterials);
      setNewCertification("");
    }
  };

  const removeCertification = (materialIndex: number, certIndex: number) => {
    const updatedMaterials = [...formData.materials];
    updatedMaterials[materialIndex].materialCertifications =
      updatedMaterials[materialIndex].materialCertifications.filter((_, i) => i !== certIndex);
    handleChange('materials', updatedMaterials);
  };

  const addRestrictedSubstance = (index: number) => {
    if (newRestrictedSubstance.trim()) {
      const updatedMaterials = [...formData.materials];
      const currentSubstances = updatedMaterials[index].restrictedSubstances || [];
      updatedMaterials[index].restrictedSubstances = [...currentSubstances, newRestrictedSubstance.trim()];
      handleChange('materials', updatedMaterials);
      setNewRestrictedSubstance("");
    }
  };

  const removeRestrictedSubstance = (materialIndex: number, substanceIndex: number) => {
    const updatedMaterials = [...formData.materials];
    updatedMaterials[materialIndex].restrictedSubstances =
      updatedMaterials[materialIndex].restrictedSubstances.filter((_, i) => i !== substanceIndex);
    handleChange('materials', updatedMaterials);
  };

  return (
    <div className="space-y-8">
      {/* Material List */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Material List</h3>
        <div className="space-y-4">
          {formData.materials.map((material, index) => (
            <Card key={index} className="p-4 space-y-4">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-medium">Material {index + 1}</h4>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeMaterial(index)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label>Material Name</Label>
                  <Input
                    value={material.materialName}
                    onChange={(e) => handleMaterialChange(index, 'materialName', e.target.value)}
                    placeholder="Enter material name"
                  />
                </div>

                <div className="space-y-2">
                  <Label>Origin Country</Label>
                  <Input
                    value={material.originCountry}
                    onChange={(e) => handleMaterialChange(index, 'originCountry', e.target.value)}
                    placeholder="Enter origin country"
                  />
                </div>

                <div className="space-y-2">
                  <Label>Recycled Content (%)</Label>
                  <Input
                    type="number"
                    value={material.recycledContent}
                    onChange={(e) => handleMaterialChange(index, 'recycledContent', Number(e.target.value))}
                    placeholder="Enter recycled content percentage"
                  />
                </div>

                <div className="space-y-2">
                  <Label>Biobased Content (%)</Label>
                  <Input
                    type="number"
                    value={material.biobasedContent}
                    onChange={(e) => handleMaterialChange(index, 'biobasedContent', Number(e.target.value))}
                    placeholder="Enter biobased content percentage"
                  />
                </div>

                <div className="space-y-2">
                  <Label>Mass Amount Value</Label>
                  <Input
                    type="number"
                    value={material.massAmount.value}
                    onChange={(e) => handleMaterialChange(index, 'massAmount', { ...material.massAmount, value: Number(e.target.value) })}
                    placeholder="Enter mass amount value"
                  />
                </div>

                <div className="space-y-2">
                  <Label>Mass Amount Unit</Label>
                  <Input
                    value={material.massAmount.unit}
                    onChange={(e) => handleMaterialChange(index, 'massAmount', { ...material.massAmount, unit: e.target.value })}
                    placeholder="Enter mass amount unit"
                  />
                </div>

                <div className="space-y-2">
                  <Label>Mass Fraction</Label>
                  <Input
                    type="number"
                    value={material.massFraction}
                    onChange={(e) => handleMaterialChange(index, 'massFraction', Number(e.target.value))}
                    placeholder="Enter mass fraction"
                  />
                </div>

                <div className="space-y-2">
                  <Label>Recycled Amount</Label>
                  <Input
                    type="number"
                    value={material.recycledAmount}
                    onChange={(e) => handleMaterialChange(index, 'recycledAmount', Number(e.target.value))}
                    placeholder="Enter recycled amount"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label>Material Type</Label>
                <Textarea
                  value={JSON.stringify(material.materialType)}
                  onChange={(e) => {
                    try {
                      const parsedValue = JSON.parse(e.target.value);
                      handleMaterialChange(index, 'materialType', parsedValue);
                    } catch (error) {
                      console.error("Failed to parse material type:", error);
                    }
                  }}
                  placeholder="Enter material type as JSON"
                />
              </div>

              <div className="space-y-2">
                <Label>MSDS Link</Label>
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    value={material.msdsLink.linkName}
                    onChange={(e) => handleMaterialChange(index, 'msdsLink', { ...material.msdsLink, linkName: e.target.value })}
                    placeholder="MSDS Link Name"
                  />
                  <Input
                    value={material.msdsLink.linkURL}
                    onChange={(e) => handleMaterialChange(index, 'msdsLink', { ...material.msdsLink, linkURL: e.target.value })}
                    placeholder="MSDS Link URL"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label>Material Certifications</Label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {material.materialCertifications?.map((cert, certIndex) => (
                    <div key={certIndex} className="bg-muted px-2 py-1 rounded-md flex items-center">
                      {cert}
                      <Button
                        variant="ghost"
                        size="sm"
                        className="ml-2"
                        onClick={() => removeCertification(index, certIndex)}
                      >
                        <X className="h-3 w-3" />
                      </Button>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    value={newCertification}
                    onChange={(e) => setNewCertification(e.target.value)}
                    placeholder="Certification name"
                  />
                  <Button
                    onClick={() => addCertification(index)}
                    className="w-full"
                    disabled={!newCertification.trim()}
                  >
                    Add Certification
                  </Button>
                </div>
              </div>

              <div className="space-y-2">
                <Label>Restricted Substances</Label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {material.restrictedSubstances?.map((substance, substanceIndex) => (
                    <div key={substanceIndex} className="bg-muted px-2 py-1 rounded-md flex items-center">
                      {substance}
                      <Button
                        variant="ghost"
                        size="sm"
                        className="ml-2"
                        onClick={() => removeRestrictedSubstance(index, substanceIndex)}
                      >
                        <X className="h-3 w-3" />
                      </Button>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    value={newRestrictedSubstance}
                    onChange={(e) => setNewRestrictedSubstance(e.target.value)}
                    placeholder="Substance name"
                  />
                  <Button
                    onClick={() => addRestrictedSubstance(index)}
                    className="w-full"
                    disabled={!newRestrictedSubstance.trim()}
                  >
                    Add Substance
                  </Button>
                </div>
              </div>

              <div className="space-y-2">
                <Label>Hazardous</Label>
                <Select
                  value={String(material.hazardous)}
                  onValueChange={(value) => handleMaterialChange(index, 'hazardous', value === 'true')}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select hazardous status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="true">True</SelectItem>
                    <SelectItem value="false">False</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </Card>
          ))}
          <Button onClick={addMaterial}>Add Material</Button>
        </div>
      </div>

      {/* Summary */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Summary</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label>Total Recycled Content (%)</Label>
            <Input
              type="number"
              value={formData.totalRecycledContent}
              onChange={(e) => handleChange('totalRecycledContent', Number(e.target.value))}
              placeholder="Enter total recycled content percentage"
            />
          </div>

          <div className="space-y-2">
            <Label>Total Biobased Content (%)</Label>
            <Input
              type="number"
              value={formData.totalBiobasedContent}
              onChange={(e) => handleChange('totalBiobasedContent', Number(e.target.value))}
              placeholder="Enter total biobased content percentage"
            />
          </div>
        </div>
      </div>

      {/* MSDS Link */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">MSDS Link</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label>Document Name</Label>
            <Input
              value={formData.msdsLink.linkName}
              onChange={(e) => handleChange('msdsLink', { ...formData.msdsLink, linkName: e.target.value })}
              placeholder="Enter document name"
            />
          </div>
          <div className="space-y-2">
            <Label>Document URL</Label>
            <Input
              value={formData.msdsLink.linkURL}
              onChange={(e) => handleChange('msdsLink', { ...formData.msdsLink, linkURL: e.target.value })}
              placeholder="Enter document URL"
            />
          </div>
        </div>
      </div>

      {/* Due Diligence Declaration */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Due Diligence Declaration</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label>Document Name</Label>
            <Input
              value={formData.dueDiligenceDeclaration.linkName}
              onChange={(e) => handleChange('dueDiligenceDeclaration', { ...formData.dueDiligenceDeclaration, linkName: e.target.value })}
              placeholder="Enter document name"
            />
          </div>
          <div className="space-y-2">
            <Label>Document URL</Label>
            <Input
              value={formData.dueDiligenceDeclaration.linkURL}
              onChange={(e) => handleChange('dueDiligenceDeclaration', { ...formData.dueDiligenceDeclaration, linkURL: e.target.value })}
              placeholder="Enter document URL"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
