import { Home, FilePlus, Grid, ChartBar, LogOut, QrCode, Search, Calendar, PlusCircle, Check } from "lucide-react";
import { ExpandableTabs } from "@/components/ui/expandable-tabs";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { DynamicWidget } from "@dynamic-labs/sdk-react-core";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Steps } from "@/components/ui/steps";
import { BasicInfoForm } from "@/components/create-dpp/BasicInfoForm";
import { ManufacturingForm } from "@/components/create-dpp/ManufacturingForm";
import { MaterialProvenanceForm } from "@/components/create-dpp/MaterialProvenanceForm";
import { SustainabilityForm } from "@/components/create-dpp/SustainabilityForm";
import { LifecycleComplianceForm } from "@/components/create-dpp/LifecycleComplianceForm";
import { ReviewForm } from "@/components/create-dpp/ReviewForm";
import { DPPFormData } from "@/types/dpp-form";
import { CreateLayout } from "@/components/create-dpp/CreateLayout";
import { DirectoryTab } from "@/components/dashboard/DirectoryTab";
import { LucideIcon } from "lucide-react";

// Define the types for our tabs
type Tab = {
  title: string;
  icon: LucideIcon;
  onClick?: () => void;
};

type Separator = {
  type: "separator";
  title: "separator";
  icon: undefined;
};

type NavigationTab = Tab | Separator;

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState<string>("Home");
  const [isChangingTab, setIsChangingTab] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [timeFilter, setTimeFilter] = useState("all");
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [currentDppIndex, setCurrentDppIndex] = useState(0);
  const {
    handleLogOut
  } = useDynamicContext();
  const navigate = useNavigate();

  const dppList = [{
    id: '001',
    name: 'DPP-001',
    status: 'active',
    createdAt: '2024-03-15',
    priority: 'high'
  }, {
    id: '002',
    name: 'DPP-002',
    status: 'pending',
    createdAt: '2024-03-14',
    priority: 'medium'
  }, {
    id: '003',
    name: 'DPP-003',
    status: 'draft',
    createdAt: '2024-03-13',
    priority: 'low'
  }, {
    id: '004',
    name: 'DPP-004',
    status: 'active',
    createdAt: '2024-03-12',
    priority: 'high'
  }, {
    id: '005',
    name: 'DPP-005',
    status: 'active',
    createdAt: '2024-03-11',
    priority: 'medium'
  }];
  const featuredDpps = [{
    id: '001',
    name: 'DPP-001',
    status: 'active',
    description: 'High-quality sustainable product with verified certifications and complete lifecycle tracking.',
    circularityScore: 85,
    carbonFootprint: '12.5',
    energyUsage: '45',
    lifecycleStage: 2,
    image: '/placeholder.svg'
  }, {
    id: '002',
    name: 'DPP-002',
    status: 'pending',
    description: 'Eco-friendly manufacturing process with minimal environmental impact.',
    circularityScore: 92,
    carbonFootprint: '8.3',
    energyUsage: '38',
    lifecycleStage: 1,
    image: '/placeholder.svg'
  }, {
    id: '003',
    name: 'DPP-003',
    status: 'draft',
    description: 'Next-generation sustainable materials with full traceability.',
    circularityScore: 78,
    carbonFootprint: '15.7',
    energyUsage: '52',
    lifecycleStage: 0,
    image: '/placeholder.svg'
  }];
  
  const navigateDpp = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setCurrentDppIndex(current => current === 0 ? featuredDpps.length - 1 : current - 1);
    } else {
      setCurrentDppIndex(current => current === featuredDpps.length - 1 ? 0 : current + 1);
    }
  };

  const handleLogoutClick = async () => {
    try {
      await handleLogOut();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleTabChange = useCallback((index: number | null) => {
    if (index === null || isChangingTab) return;
    
    const tab = navigationTabs[index];
    if (!tab) return;

    if ('onClick' in tab && tab.onClick) {
      tab.onClick();
    } else if ('type' in tab) {
      return;
    } else if (tab.title !== activeTab) {
      setIsChangingTab(true);
      setActiveTab(tab.title);
      setTimeout(() => setIsChangingTab(false), 100);
    }
  }, [activeTab, isChangingTab, handleLogOut, navigate]);

  const navigationTabs: NavigationTab[] = [{
    title: "Home",
    icon: Home
  }, {
    title: "Create",
    icon: FilePlus
  }, {
    type: "separator",
    title: "separator",
    icon: undefined
  }, {
    title: "Directory",
    icon: Grid
  }, {
    title: "Analytics",
    icon: ChartBar
  }, {
    type: "separator",
    title: "separator",
    icon: undefined
  }, {
    title: "Logout",
    icon: LogOut,
    onClick: handleLogoutClick
  }];

  const renderCreateContent = () => (
    <CreateLayout />
  );

  const renderHomeContent = () => {
    const currentDpp = featuredDpps[currentDppIndex];
    return <div className="grid grid-cols-12 gap-6 w-full max-w-[1800px] mx-auto p-6">
        {/* Left Column - Stats and DPP List */}
        <div className="col-span-8 space-y-6">
          {/* Stats Row */}
          <div className="grid grid-cols-3 gap-6">
            <Card className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-[0_4px_12px_-2px_rgba(0,0,0,0.06)] rounded-2xl overflow-hidden">
              <CardHeader>
                <CardTitle className="text-[#403E43] text-lg font-medium">Total Active DPPs</CardTitle>
              </CardHeader>
              <CardContent className="flex items-center justify-center">
                <span className="text-5xl font-semibold text-neutral-800">42</span>
              </CardContent>
            </Card>

            <Card className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-[0_4px_12px_-2px_rgba(0,0,0,0.06)] rounded-2xl overflow-hidden">
              <CardHeader>
                <CardTitle className="text-[#403E43] text-lg font-medium">Average Carbon Footprint</CardTitle>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="flex items-baseline gap-2">
                  <span className="text-4xl font-semibold text-neutral-800">14.2</span>
                  <span className="text-sm text-[#8E9196]">kg CO₂/unit</span>
                </div>
                <Progress value={65} className="h-1.5 bg-[#F1F0FB]">
                  <div className="h-full bg-[#33C3F0] rounded-full" />
                </Progress>
                <span className="text-xs text-neutral-800">-12% from last month</span>
              </CardContent>
            </Card>

            <Card className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-[0_4px_12px_-2px_rgba(0,0,0,0.06)] rounded-2xl overflow-hidden">
              <CardHeader>
                <CardTitle className="text-[#403E43] text-lg font-medium">Average Circularity Score</CardTitle>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="flex items-baseline gap-2">
                  <span className="text-4xl font-semibold text-neutral-800">85</span>
                  <span className="text-sm text-[#8E9196]">%</span>
                </div>
                <Progress value={85} className="h-1.5 bg-[#F1F0FB]">
                  <div className="h-full bg-[#33C3F0] rounded-full" />
                </Progress>
                <span className="text-xs text-neutral-800">+5% from last month</span>
              </CardContent>
            </Card>
          </div>

          {/* DPP List */}
          <Card className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-[0_4px_12px_-2px_rgba(0,0,0,0.06)] rounded-2xl overflow-hidden">
            <CardHeader className="space-y-4 border-b border-[#F1F0FB]">
              <div className="flex items-center justify-between">
                <CardTitle className="text-[#403E43] text-lg font-medium">All DPPs</CardTitle>
                <div className="flex items-center space-x-2">
                  <select className="px-3 py-1.5 bg-white/60 border border-[#F1F0FB] rounded-lg text-[#403E43] text-sm" value={timeFilter} onChange={e => setTimeFilter(e.target.value)}>
                    <option value="all">All Time</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                    <option value="year">This Year</option>
                  </select>
                  <select className="px-3 py-1.5 bg-white/60 border border-[#F1F0FB] rounded-lg text-[#403E43] text-sm" value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
                    <option value="all">All Status</option>
                    <option value="active">Active</option>
                    <option value="pending">Pending</option>
                    <option value="draft">Draft</option>
                  </select>
                </div>
              </div>
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-[#8E9196]" />
                <input type="text" placeholder="Search DPPs..." className="w-full pl-9 pr-4 py-2 bg-white/60 border border-[#F1F0FB] rounded-lg focus:outline-none focus:border-[#33C3F0] focus:ring-1 focus:ring-[#33C3F0] transition-colors" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-3 max-h-[320px] overflow-y-auto pr-2">
                {dppList.map(dpp => <div key={dpp.id} className="p-4 bg-white/40 border border-[#F1F0FB] rounded-xl hover:border-[#33C3F0] hover:shadow-[0_4px_12px_-2px_rgba(51,195,240,0.1)] cursor-pointer">
                    <div className="flex justify-between items-center mb-2">
                      <span className="font-medium text-[#403E43]">{dpp.name}</span>
                      <span className={`text-sm px-2 py-0.5 rounded-full ${dpp.status === 'active' ? 'bg-[#F2FCE2] text-[#587848]' : dpp.status === 'pending' ? 'bg-[#FEF7CD] text-[#B59F3B]' : 'bg-[#F1F0FB] text-[#8E9196]'}`}>
                        {dpp.status.charAt(0).toUpperCase() + dpp.status.slice(1)}
                      </span>
                    </div>
                    <div className="flex items-center text-xs text-[#8E9196]">
                      <Calendar className="w-3 h-3 mr-1" />
                      Created: {dpp.createdAt}
                    </div>
                  </div>)}
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Column - Latest DPPs */}
        <div className="col-span-4">
          <Card className="bg-white/60 backdrop-blur-lg border border-white/20 shadow-[0_4px_12px_-2px_rgba(0,0,0,0.06)] rounded-2xl overflow-hidden h-full">
            <CardHeader className="border-b border-[#F1F0FB]">
              <div className="flex items-center justify-between">
                <CardTitle className="text-[#403E43] text-lg font-medium">Latest DPPs</CardTitle>
                <div className="flex gap-2">
                  <button onClick={() => navigateDpp('prev')} className="w-8 h-8 rounded-full bg-white/60 border border-[#F1F0FB] text-[#403E43] flex items-center justify-center hover:border-[#33C3F0] hover:text-[#33C3F0] transition-colors">
                    ←
                  </button>
                  <button onClick={() => navigateDpp('next')} className="w-8 h-8 rounded-full bg-white/60 border border-[#F1F0FB] text-[#403E43] flex items-center justify-center hover:border-[#33C3F0] hover:text-[#33C3F0] transition-colors">
                    →
                  </button>
                </div>
              </div>
            </CardHeader>
            <CardContent className="p-6">
              <div className="space-y-6">
                {/* Product Header */}
                <div className="flex items-start gap-6">
                  <div className="w-32 h-32 bg-white/40 rounded-xl flex items-center justify-center flex-shrink-0 border border-[#F1F0FB]">
                    <img src={currentDpp.image} alt="Product" className="w-28 h-28 object-cover" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h3 className="text-xl font-medium text-[#403E43] mb-2 truncate">
                      {currentDpp.name}
                    </h3>
                    <div className="flex items-center gap-2 mb-3">
                      <span className="px-2 py-1 bg-[#F2FCE2] text-[#587848] rounded-full text-sm">
                        {currentDpp.status.charAt(0).toUpperCase() + currentDpp.status.slice(1)}
                      </span>
                      <span className="text-sm text-[#8E9196]">Type: Product</span>
                    </div>
                    <p className="text-sm text-[#8E9196] line-clamp-3">
                      {currentDpp.description}
                    </p>
                  </div>
                </div>

                {/* Metrics Section */}
                <div className="space-y-4 pt-4 border-t border-[#F1F0FB]">
                  <div>
                    <div className="flex justify-between mb-2">
                      <span className="text-sm font-medium text-[#403E43]">Circularity Score</span>
                      <span className="text-sm font-medium text-[#33C3F0]">{currentDpp.circularityScore}%</span>
                    </div>
                    <Progress value={currentDpp.circularityScore} className="h-1.5 bg-[#F1F0FB]">
                      <div className="h-full bg-[#33C3F0] rounded-full" />
                    </Progress>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="p-4 bg-white/40 rounded-xl border border-[#F1F0FB]">
                      <span className="text-sm text-[#8E9196] block mb-1">Carbon Footprint</span>
                      <span className="text-lg font-medium text-[#403E43]">{currentDpp.carbonFootprint} kg CO₂</span>
                    </div>
                    <div className="p-4 bg-white/40 rounded-xl border border-[#F1F0FB]">
                      <span className="text-sm text-[#8E9196] block mb-1">Energy Usage</span>
                      <span className="text-lg font-medium text-[#403E43]">{currentDpp.energyUsage} kWh</span>
                    </div>
                  </div>
                </div>

                {/* Lifecycle Section */}
                <div className="pt-4 border-t border-[#F1F0FB]">
                  <h4 className="text-sm font-medium text-[#403E43] mb-3">Lifecycle Stage</h4>
                  <div className={`flex justify-between items-center mb-2`}>
                    <div className={`w-2 h-2 rounded-full ${currentDpp.lifecycleStage >= 0 ? 'bg-[#33C3F0]' : 'bg-[#F1F0FB]'}`} />
                    <div className={`flex-1 h-0.5 mx-1 ${currentDpp.lifecycleStage >= 1 ? 'bg-[#33C3F0]' : 'bg-[#F1F0FB]'}`} />
                    <div className={`w-2 h-2 rounded-full ${currentDpp.lifecycleStage >= 1 ? 'bg-[#33C3F0]' : 'bg-[#F1F0FB]'}`} />
                    <div className={`flex-1 h-0.5 mx-1 ${currentDpp.lifecycleStage >= 2 ? 'bg-[#33C3F0]' : 'bg-[#F1F0FB]'}`} />
                    <div className={`w-2 h-2 rounded-full ${currentDpp.lifecycleStage >= 2 ? 'bg-[#33C3F0]' : 'bg-[#F1F0FB]'}`} />
                    <div className={`flex-1 h-0.5 mx-1 ${currentDpp.lifecycleStage >= 3 ? 'bg-[#33C3F0]' : 'bg-[#F1F0FB]'}`} />
                    <div className={`w-2 h-2 rounded-full ${currentDpp.lifecycleStage >= 3 ? 'bg-[#33C3F0]' : 'bg-[#F1F0FB]'}`} />
                  </div>
                  <div className="flex justify-between text-xs">
                    <span className={`font-medium ${currentDpp.lifecycleStage >= 0 ? 'text-[#403E43]' : 'text-[#8E9196]'}`}>Production</span>
                    <span className={`font-medium ${currentDpp.lifecycleStage >= 1 ? 'text-[#403E43]' : 'text-[#8E9196]'}`}>Distribution</span>
                    <span className={`font-medium ${currentDpp.lifecycleStage >= 2 ? 'text-[#403E43]' : 'text-[#8E9196]'}`}>Use</span>
                    <span className={`font-medium ${currentDpp.lifecycleStage >= 3 ? 'text-[#403E43]' : 'text-[#8E9196]'}`}>End-of-Life</span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>;
  };

  const renderDirectoryContent = () => (
    <DirectoryTab />
  );

  const renderContent = () => {
    if (activeTab === "Home") {
      return renderHomeContent();
    }
    if (activeTab === "Create") {
      return renderCreateContent();
    }
    if (activeTab === "Directory") {
      return renderDirectoryContent();
    }
    return null;
  };

  return (
    <div className="relative min-h-screen bg-transparent">
      {/* Background Image */}
      <div className="fixed inset-0 -z-10">
        <img
          src="/ben-neale-29w9FiMWSr8-unsplash.jpg"
          alt="Background"
          className="w-full h-full object-cover opacity-100"
        />
        {/* Frosted Glass Overlay */}
        <div className="absolute inset-0 backdrop-blur-xl bg-white/30" />
      </div>

      {/* Header with Dynamic Widget */}
      <div className="fixed top-0 right-0 z-50 p-4">
        <DynamicWidget />
      </div>

      <div className="relative min-h-screen pb-20 bg-transparent pt-16">
        <div className="container mx-auto py-8">
          {renderContent()}
        </div>
        
        <div className="fixed bottom-4 left-0 right-0 flex justify-center px-4">
          <ExpandableTabs 
            tabs={navigationTabs} 
            className="border-none shadow-lg backdrop-blur-lg bg-white/20" 
            onChange={handleTabChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
